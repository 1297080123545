import React from 'react'
import {Button, Modal} from 'react-bootstrap'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faStopwatch} from '@fortawesome/free-solid-svg-icons'
import './OutOfTime.scss'
import {timeOut} from 'data/constant'
import {getSkillTestUrl} from 'enums/pageUrl';

export default function OutOfTimeModal(props) {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            keyboard={false}
            backdrop="static"
        >
            <Modal.Body className="modal-body">
                <FontAwesomeIcon className="stop-watch-icon" icon={faStopwatch} size="7x"/>
                <Modal.Title className="modal-custom-title">{timeOut}</Modal.Title>
                <p className="modal-text">
                    Veuillez cliquez sur suivant pour continuer
                </p>
                <Button size="lg"
                        onClick={() => props.history.push(getSkillTestUrl(props.sectionName, 'validation', null))}>Suivant</Button>
            </Modal.Body>
        </Modal>
    );
}