import React, {Fragment, useEffect, useState} from 'react'
import NavigationBar from 'components/admin/navbar/NavigationBar'
import DocumentsCandidate from './documents/DocumentsCandidate'
import Profile from './profile/Profile'
import './InfoCandidate.scss'
import {deleteCandidate, getCandidateById} from 'service/admin'
import {toast} from 'react-toastify'
import ResultCandidate from './results/ResultCandidate';
import Photo from "../Photo";
import DeleteButton from "../../../deleteButton/DeleteButton";
import {PAGES} from "../../../../enums/pageUrl";
import { Col, Row } from 'react-bootstrap'
import InterviewsCandidate from "./interviews/InterviewsCandidate";

export default function InfoCandidate({history, match}) {

    const {params: {id}} = match;

    const [candidate, setCandidate] = useState(null);

    const getResultIcon = () => {
        if (candidate.testDate) {
            if (candidate.testDate.end) {
                return "check-icon";
            } else if (candidate.testDate.start) {
                return "waiting-icon"
            }
            return null;
        }
    }

    const getCandidate = () => {
        getCandidateById(id)
            .then(result => {
                setCandidate(result.data);
            })
            .catch(error => {
                toast.error(error.message, {position: toast.POSITION.TOP_RIGHT})
            })
    }

    useEffect(() => {
        getCandidate()
    }, []);

    const handleDelete = () => {
        deleteCandidate(candidate.candidateId)
            .then(() => {
                toast.success('Candidat supprimé', {position: toast.POSITION.TOP_RIGHT})
                history.push(PAGES.CANDIDATES_ADMIN);
            })
            .catch((error) => {
                toast.error(error.message, {position: toast.POSITION.TOP_RIGHT})
            })
    }

    let percentageScore = candidate?.globalScore && candidate?.globalNumbQuestions ?
        Math.round((candidate.globalScore / candidate.globalNumbQuestions) * 100) : null;

    return (
        <Fragment>
            <NavigationBar/>
            {candidate &&
                <div className="admin">
                    <div className="candidates-home">
                        <div className="candidate-profile">
                            <Photo keyPhoto={candidate.photo}/>
                            <div className="candidates-info d-flex flex-column align-items-start">
                                <div className="name">
                                    {`${candidate.firstName} ${candidate.lastName}`}
                                    <div className={`result-icon ${getResultIcon()}`}/>
                                </div>
                                {percentageScore && <div className="pourcentage-score">
                                    <span>{percentageScore}%</span>
                                </div>}
                                <DeleteButton size="sm" className="m-0" buttonText="Supprimer le candidat" handleDelete={handleDelete}/>
                            </div>
                        </div>
                        <Row className="mt-5">
                            <Col md={4} className="border-separator pr-3">
                                <h3>Informations<span className="accentuated-title"> personnelles</span></h3>
                                <Profile candidate={candidate}/>
                                <DocumentsCandidate candidate={candidate}/>
                            </Col>
                            <Col md={4} className="border-separator px-3">
                                <h3>Résultats des<span className="accentuated-title"> tests</span></h3>
                                <ResultCandidate candidate={candidate} reloadData={getCandidate}/>
                            </Col>
                            <Col md={4} className="pl-3">
                                <h3>Suivi des<span className="accentuated-title"> entretiens</span></h3>
                                <InterviewsCandidate candidate={candidate} candidateUpdated={(data) => setCandidate(data)}/>
                            </Col>
                        </Row>
                    </div>
                </div>
            }
        </Fragment>
    )
}