import React, {Fragment, useEffect, useState} from 'react';
import NavigationBar from '../../navbar/NavigationBar';
import {toast} from 'react-toastify';
import {deleteQuestionImage, deleteSection, getSectionById, updateSection} from 'service/question';
import './SectionPage.scss';
import {Button, Form, Spinner} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import QuestionConfigContainer from './questions/QuestionConfigContainer';
import SectionForm from './SectionForm';
import Modal from 'react-bootstrap/Modal';
import {PAGES} from 'enums/pageUrl';
import {SECTION_NAME} from 'enums/sectionName';
import {faArrowRight} from "@fortawesome/free-solid-svg-icons";

export default function SectionPage({history, match}) {

    const NEW_QUESTION_INIT = {
        header: null,

        image: null,
        choices: []
    }

    const NEW_SECTION_INIT = {
        sectionId: null,
        sectionName: '',
        icon: null,
        label: '',
        questions: [],
        rules: '',
        time: '',
        validationMessage: ''
    }

    const {params: {id, sectionName}} = match;

    const [section, setSection] = useState(null);
    const [loading, setLoading] = useState(true);
    const [show, setShow] = useState(false);

    //Hook pour modifier la question à ajouter à la section
    //Etat initial de type QCM
    const [newQuestion, setNewQuestion] = useState(NEW_QUESTION_INIT);

    const handleClose = () => setShow(false);

    /**
     * @desc Supprime la section
     */
    const handleDelete = () => {
        deleteSection(section.sectionId)
            .then(() => {
                //Après la suppression, renvoie à la page de configuration
                toast.success('Section supprimée', {position: toast.POSITION.TOP_RIGHT});
                history.push(PAGES.CONFIG_PAGE_ADMIN);
            })
            .catch(error => {
                toast.error(error.message, {position: toast.POSITION.TOP_RIGHT})
            })

    }

    useEffect(() => {
        if (id) {
            getSectionById(id)
                .then(response => {
                    setSection(response.data);
                    setLoading(false);
                })
                .catch(error => {
                    setLoading(false);
                    toast.error(error.message, {position: toast.POSITION.TOP_RIGHT})
                })
        } else {
            setSection(NEW_SECTION_INIT);
            setLoading(false);
        }
    }, [id]);

    /**
     * @desc Ajoute ou modifie une question
     * @param question - question à ajouter/modifier
     * @param index - index de la question dans la section
     */
    const addOrUpdateQuestion = async (question, index) => {
        let newSection = {...section};
        let questionList = [...section.questions];
        if (index != null) {
            questionList[index] = question;
            //Suppression de l'ancienne image si il y en a une
            if (section.questions[index].image && question.image !== section.questions[index].image) {
                await deleteQuestionImage(section.questions[index].image);
            }
        } else {
            questionList.unshift(question);
            setNewQuestion(NEW_QUESTION_INIT);
        }
        newSection.questions = questionList;
        sendNewSection(newSection);
    }

    /**
     * @desc Change le type de question (QCM ou question ouverte)
     */
    const toggleQuestionType = () => {
        let toggledQuestion;
        //Si c'est une question de type QCM, on ajoute une réponse et on supprime ses choix
        if (newQuestion.choices) {
            toggledQuestion = {
                ...newQuestion,
                answer: {
                    requiresCorrection: true,
                    value: ''
                }
            };
            delete toggledQuestion.choices;
            setNewQuestion(toggledQuestion);
        } else {
            //Sinon on fait l'inverse
            toggledQuestion = {
                ...newQuestion,
                choices: [
                    {label: '', correct: false}
                ]
            };
            delete toggledQuestion.answer;
            setNewQuestion(toggledQuestion);
        }
    }

    /**
     * @desc Supprime une question de la section
     * @param index - index de la question dans la section
     */
    const deleteQuestion = async index => {
        //Suppression de l'image si il y en a une
        if (section.questions[index].image) {
            await deleteQuestionImage(section.questions[index].image);
        }
        let newSection = {...section};
        let questionList = [...section.questions];
        questionList.splice(index, 1);
        newSection.questions = questionList;
        sendNewSection(newSection);
    }

    /**
     * @desc Met à jour la section dans dynamoDB
     * @param section - section à mettre à jour
     */
    const sendNewSection = section => {
        updateSection(section.sectionId, section)
            .then((response) => {
                toast.success('Section mise à jour', {position: toast.POSITION.TOP_RIGHT});
                setSection(response.data);
            })
            .catch(() => {
                toast.error('Erreur lors de la mise à jour de la section', {position: toast.POSITION.TOP_RIGHT});
            });
    }

    const isNewSection = () => {
        //Si id n'existe pas, c'est une nouvelle section
        return !id
    }

    /**
     * @desc Construit un composant pour chaque question de la section
     * @param questionList - liste de questions
     */
    const questionContainerList = (questionList) => {
        if (Array.isArray(questionList) && questionList.length > 0) {
            return questionList.map((question, index) =>
                <Card key={index}>
                    <Accordion.Toggle as={Card.Header} eventKey={index + 1} className="accordeon-section d-flex justify-content-between align-item-center">
                        <span><strong>Question N°{index + 1}</strong> - {question.header}</span>
                        <span><FontAwesomeIcon className="icon m-0" icon={faArrowRight} size="sm"/></span>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey={index + 1} className="section-form">
                        <QuestionConfigContainer
                            index={index}
                            question={question}
                            sectionName={sectionName}
                            sectionId={id}
                            addOrUpdateQuestion={addOrUpdateQuestion}
                            deleteQuestion={deleteQuestion}
                        />
                    </Accordion.Collapse>
                </Card>
            );
        } else {
            return null;
        }
    };

    return (
        <Fragment>
            <NavigationBar/>
            <div className="admin candidates-home">
                {
                    loading ?
                        <Spinner
                            size="sm"
                            as="span"
                            className="mr-2"
                            animation="border"
                            role="status"
                            aria-hidden="true"
                        />
                        :
                        <>
                            <div className="d-flex justify-content-between align-items-center">
                                <div className="d-flex align-items-center">
                                    <FontAwesomeIcon className="mr-2 mb-3 fa-2x" icon={section.icon} />
                                    <h2>{section.label ?
                                        <span className="accentuated-title">{section.label}</span> :
                                        <span>Nouvelle <span className="accentuated-title">section</span></span>
                                    }</h2>
                                </div>
                                <div>
                                    {
                                        !isNewSection() &&
                                        <Button
                                            variant="outline-danger"
                                            size="sm"
                                            className="mb-4"
                                            onClick={() => setShow(true)}>
                                            Supprimer le test
                                        </Button>
                                    }
                                </div>
                            </div>
                            {
                                isNewSection() ? <SectionForm section={section} history={history}/> :
                                    <Accordion className="mb-3">
                                        <Card key={1} className="mb-3 accordeon-section-with-border">
                                            <Accordion.Toggle as={Card.Header} eventKey={1} className="accordeon-section d-flex justify-content-between align-items-center">
                                                <span className="font-weight-bold">Configuration de la section</span>
                                                <span><FontAwesomeIcon className="icon m-0" icon={faArrowRight} size="sm"/></span>
                                            </Accordion.Toggle>
                                            <Accordion.Collapse eventKey={1} className="p-4">
                                                <SectionForm section={section} sendNewSection={sendNewSection}/>
                                            </Accordion.Collapse>
                                        </Card>
                                        <Card key={2}>
                                            <Accordion.Toggle as={Card.Header} eventKey={2} className="accordeon-section d-flex justify-content-between align-items-center">
                                                <span className="font-weight-bold">Nouvelle question</span>
                                                <span><FontAwesomeIcon className="icon m-0" icon={faArrowRight} size="sm"/></span>
                                            </Accordion.Toggle>
                                            <Accordion.Collapse eventKey={2} className="section-form">
                                                <div>
                                                    {
                                                        //Conversion en question ouverte impossible pour le test d'auto-perception
                                                        sectionName !== SECTION_NAME.AUTO_PERCEPTION &&
                                                        <Form.Check // prettier-ignore
                                                            type="switch"
                                                            className="ml-4 mt-4"
                                                            id="custom-switch"
                                                            label="Question ouverte"
                                                            onChange={toggleQuestionType}
                                                        />
                                                    }
                                                    <QuestionConfigContainer
                                                        index={null}
                                                        sectionName={sectionName}
                                                        sectionId={id}
                                                        question={newQuestion}
                                                        addOrUpdateQuestion={addOrUpdateQuestion}
                                                    />
                                                </div>
                                            </Accordion.Collapse>
                                        </Card>
                                    </Accordion>
                            }
                            <Accordion>
                                {
                                    questionContainerList(section.questions)
                                }
                            </Accordion>
                            <Modal show={show} onHide={handleClose}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Suppression de la section</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>Êtes-vous sûr de vouloir supprimer la
                                    section <b>{section.label}</b> ?</Modal.Body>
                                <Modal.Footer>
                                    <Button variant="outline-secondary" onClick={handleClose}>
                                        Annuler
                                    </Button>
                                    <Button variant="danger" onClick={handleDelete}>
                                        Confirmer
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        </>
                }
            </div>
        </Fragment>
    )

}