import React from 'react'
import {Modal} from 'react-bootstrap'
import './OutOfTime.scss'
import logo from 'assets/logo2019.svg';

export default function EndModal(props) {

    return (
        <Modal
            show={props.showModal}
            size="lg"
            centered
            keyboard={false}
            backdrop="static"
        >
            <Modal.Body className="modal-body">
                <img src={logo} className="app-logo" alt="logo"/>
                <Modal.Title className="modal-custom-title">Merci d'avoir réalisé le test !</Modal.Title>
                <p className="modal-text">Nous vous recontactons bientôt &#128515;</p>
            </Modal.Body>
        </Modal>

    );
}
