import React from 'react'
import {withRouter} from 'react-router-dom'
import {STATUT} from 'enums/candidateStatus'
import Table from 'react-bootstrap/Table';

function Profile(props) {
    const {candidate} = props;

    return (
        <div>
            <Table className="profile-table">
                <tbody>
                <tr>
                    <th className="small-col text-left">
                        <span>Nom</span>
                    </th>
                    <th className="text-left">
                        <span>{candidate.lastName}</span>
                    </th>
                </tr>
                <tr>
                    <th className="small-col text-left">
                        <span>Prénom</span>
                    </th>
                    <th className="text-left">
                        <span>{candidate.firstName}</span>
                    </th>
                </tr>
                <tr>
                    <th className="small-col text-left">
                        <span>Email</span>
                    </th>
                    <th className="text-left">
                        <span>{candidate.email}</span>
                    </th>
                </tr>
                {
                    candidate.phone &&
                    <tr>
                        <th className="small-col text-left">
                            <span>Téléphone</span>
                        </th>
                        <th className="text-left">
                            <span>{candidate.phone}</span>
                        </th>
                    </tr>
                }
                <tr>
                    <th className="small-col text-left">
                        <span>Contrat</span>
                    </th>
                    <th className="text-left">
                        <span>{STATUT[candidate.status]}</span>
                    </th>
                </tr>
                <tr>
                    <th className="small-col text-left">
                        <span>Agence</span>
                    </th>
                    <th className="text-left">
                        <span>{candidate.agency ?? 'Paris'}</span>
                    </th>
                </tr>
                {
                    candidate.school &&
                    <tr>
                        <th className="small-col text-left">
                            <span>École</span>
                        </th>
                        <th className="text-left">
                            <span>{candidate.school}</span>
                        </th>
                    </tr>
                }
                {
                    candidate.promotionYear &&
                    <tr>
                        <th className="small-col text-left">
                            <span>Année de promotion</span>
                        </th>
                        <th className="text-left">
                            <span>{candidate.promotionYear}</span>
                        </th>
                    </tr>
                }
                {
                    candidate.code &&
                    <tr>
                        <th className="small-col text-left">
                            <span>Code</span>
                        </th>
                        <th className="text-left">
                            <span>{candidate.code}</span>
                        </th>
                    </tr>
                }
                </tbody>
            </Table>
        </div>
    )
}

export default withRouter(Profile);
