import React, {useState} from 'react'
import {Button, Form, Spinner} from 'react-bootstrap'
import {toast} from 'react-toastify'
import {getConnectedUserInfo} from 'service/admin';
import {useFormFields} from 'utils/hooksForms';

export default function NewComment(props) {

    const [fields, setFields] = useFormFields({
        commentText: props.comment ? props.comment.text : '',
    });

    const handleSubmit = event => {
        props.setLoading && props.setLoading(true);
        event.preventDefault();

        if (props.comment) {
            const updatedComment = {...props.comment};
            updatedComment.text = fields['commentText'];
            saveComment(updatedComment);
        } else {
            //Récupération du nom de l'utilisateur connecté
            getConnectedUserInfo()
                .then((response) => {
                    const newComment = {
                        text: fields['commentText'],
                        author: response.data.name,
                        date: Date.now()
                    };
                    saveComment(newComment);
                })
                .catch(() => {
                    props.setLoading && props.setLoading(false);
                    toast.error('Erreur lors de l\'ajout du commentaire', {position: toast.POSITION.TOP_RIGHT});
                });
        }
    };

    const saveComment = (newComment) => {
        props.addComment(newComment);
        setFields({
            target: {
                name: 'commentText',
                value: ''
            }
        });
    }

    return (
        <Form className="mb-4" onSubmit={handleSubmit}>
            <Form.Group>
                <Form.Control
                    as="textarea"
                    placeholder="Ajouter un commentaire"
                    rows="5"
                    name="commentText"
                    value={fields['commentText']}
                    onChange={setFields}
                    required
                />
            </Form.Group>
            <Button
                variant="primary"
                size="sm"
                type="submit"
            >
                {props.loading &&
                <Spinner
                    size="sm"
                    as="span"
                    className="mr-2"
                    animation="border"
                    role="status"
                    aria-hidden="true"
                />
                }
                Enregistrer
            </Button>
        </Form>
    )
}