import React, {useEffect} from 'react'
import {Button} from 'react-bootstrap'
import './HomePage.scss'
import qs from 'qs'
import {TOKEN, ACCESS_TOKEN, INTENDED_URL} from 'data/constant'
import {PAGES} from 'enums/pageUrl';
import CoreozLogo from 'assets/CoreozLogo.png'
import AwsLogo from 'assets/aws.webp'

import config from "config";

const HomePage = (props) => {

    const {location} = props;
    //URL de la forme <lien vers cognito>/<paramètre de configuration>&redirect_uri=
    //On ajoute l'uri de redirection ici pour ne pas avoir à changer de configuration entre les environnement de dev et d'intégration
    const COGNITO_AUTH_URL = config.cognito.LOGIN_URL + window.location.origin + '/admin';
    // Si l'on a été rédirigé vers la page de login admin en tentant d'accéder à une page protégée sans être connecté
    if (location.state) {
        sessionStorage.setItem(INTENDED_URL, location.state.from.pathname);
    }
    useEffect(() => {
        if (location.hash) {
            const token = qs.parse(location.hash);
            if(token['#id_token']){
                sessionStorage.setItem(TOKEN, token['#id_token']);
                sessionStorage.setItem(ACCESS_TOKEN, token['access_token']);
                props.history.push(sessionStorage.getItem(INTENDED_URL) || PAGES.CANDIDATES_ADMIN);
                sessionStorage.removeItem(INTENDED_URL);
            }
        }
    }, [location.hash, props.history]);

    return (
        <div className="container-login">
            <div className="left-container">
                <div className="brand">
                    <h1>Steaminterview</h1>
                    <img src={CoreozLogo} alt="Logo Coreoz"></img>
                </div>
                <div className="left-content">
                    <h3>
                        Bienvenue <br />
                        sur l&apos;interface Steaminterview de Coreoz
                    </h3>
                </div>
            </div>
            <div className="right-container">
                <div className="button-connexion">
                    <Button
                        variant="light"
                        size="lg"
                        href={COGNITO_AUTH_URL}
                    >
                        <img src={AwsLogo} alt="Logo Aws"></img>
                        Se connecter
                    </Button>
                </div>
            </div>
        </div>
    )
};

export default HomePage;
