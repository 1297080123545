//Formate une date au format dd/MM/yyyy HH:mm
export function formatDateToDateTime(date) {
    let stringDate = new Date(date).toLocaleString();
    let splitString = stringDate.split(":");
    return splitString.slice(0, -1).join(':');
}

//Renvoie le nombre d'heures et de minutes depuis la date en paramètre
//Renvoie null si la différence fait plus de 20h
export function getTimeSinceDate(date) {
    const now = Date.now();
    const start = new Date(date);
    const diffTime = Math.abs(now - start);
    const diffMinutes = Math.ceil(diffTime / (1000 * 60));
    if (diffMinutes < 60) {
        return diffMinutes + " minute(s)";
    } else if (60 <= diffMinutes && diffMinutes < 1200) {
        return Math.floor(diffMinutes / 60) + " heure(s) " + diffMinutes % 60 + " minute(s)";
    } else {
        return null;
    }
}