import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'App.scss'
import {BrowserRouter as Router} from 'react-router-dom'
import Routes from 'Routes'
import {toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import 'react-datetime/css/react-datetime.css'
import {library} from '@fortawesome/fontawesome-svg-core';
import * as Icons from '@fortawesome/free-solid-svg-icons';
import * as IconsBrands from '@fortawesome/free-brands-svg-icons';

const iconList = Object.keys(Icons)
    .filter((key) => !['fas', 'prefix'].includes(key))
    .map((icon) => Icons[icon]);

const iconListBrand = Object.keys(IconsBrands)
    .filter((key) => !['fab', 'prefix'].includes(key))
    .map((icon) => IconsBrands[icon]);

library.add(...iconList);
library.add(...iconListBrand);

toast.configure();

function App() {

    return (
        <Router>
            <div className="app">
                <Routes/>
            </div>
        </Router>
    );
}

export default App;
