import React, {Fragment, useEffect, useState} from 'react'
import {Button, Col, Form, Row, Spinner} from 'react-bootstrap'
import './FormUser.scss'
import Header from 'components/header/Header'
import UploadFile from './UploadFile'
import {useDispatch, useSelector} from 'react-redux'
import {getCandidate, requestUploadFile, updateCandidate as updateCandidateBDD} from 'service/candidate'
import uploadFile from 'service/utils/uploadFile';
import {updateCandidate as updateCandidateStore} from 'store/actions/actionsCandidate'
import {toast} from 'react-toastify'
import {SESSION_ID} from 'data/constant'
import useLoader from 'components/loader/useLoader'
import {getRulesPageUrl} from 'enums/pageUrl'


export default function FormUser({history}) {

    const dispatch = useDispatch();

    const [candidate, setCandidate] = useState(useSelector(state => state.candidate));
    // Hook pour afficher un composant loader
    const [loader, showLoader, hideLoader] = useLoader();

    // indicateur de chargement
    const [loading, setLoading] = useState(false);

    // état des champs formulaire
    const [fields, setFields] = useState({
        firstName: candidate ? candidate.firstName : '',
        lastName: candidate ? candidate.lastName : '',
        phone: candidate ? candidate.phone : '',
        school: candidate ? candidate.school : '',
        photo: '',
        cv: ''
    });

    // Quand le candidat change, s'il est null on le met à jour
    useEffect(() => {
        if (!candidate) {
            showLoader()
            getCandidate(sessionStorage.getItem(SESSION_ID))
                .then(result => {
                    hideLoader()
                    const {data} = result
                    setCandidate(data);
                    setFields({
                        firstName: data.firstName,
                        lastName: data.lastName,
                        phone: data.phone,
                        school: data.school,
                        photo: '',
                        cv: ''
                    })
                })
                .catch((error) => {
                    hideLoader()
                    toast.error(error.message, {position: toast.POSITION.TOP_RIGHT})
                })
        }
    }, [candidate, hideLoader, showLoader]);

    // Met à jour l'état du formulaire lorsque l'utilisateur saisit un champ
    const handleChange = (event) => {
        if ((event.target.id !== 'photo' && event.target.id !== 'cv')) {
            setFields({
                ...fields,
                [event.target.id]: event.target.value,
            })
        }
        // Cas input file (photo ou cv)
        else {
            // On active le loader
            setLoading(true);
            const eventId = event.target.id;
            // fichier de l'input
            const file = event.target.files[0];
            // Id du candidat
            const candidateId = sessionStorage.getItem(SESSION_ID)

            // Data à envoyer à la requête
            const data = {
                name: `${candidateId}/${file.name}`,    // Clé du fichier
                type: file.type,                        // Type du fichier
            };
            // Upload du fichier directement dans s3
            directUploadToS3(data, file)
                .then(() => {
                    setLoading(false);
                    setFields({
                        ...fields,
                        [eventId]: data.name,
                    });
                })
                .catch(() => {
                    setLoading(false);
                    toast.error(
                        'Erreur lors de l\'upload du fichier'
                        , {position: toast.POSITION.TOP_RIGHT}
                    );
                })
        }
    };

    function directUploadToS3(data, file) {
        // Récupère l'url signé pour upload le fichier
        return requestUploadFile(candidate.candidateId, data)
            .then(result => {
                //upload du fichier dans s3
                uploadFile(result.data, file)
            })
    }


    // Soumission du formulaire et enregistrement des infos du candidat
    function handleSubmit(event) {
        event.preventDefault();
        setLoading(true);

        const newCandidate = {
            ...candidate,
            firstName: fields.firstName,
            lastName: fields.lastName,
            phone: fields.phone,
            school: fields.school,
            photo: fields.photo !== '' ? fields.photo : null,
            cv: fields.cv !== '' ? fields.cv : null
        };

        updateCandidateBDD(newCandidate)
            .then(() => {
                setLoading(false);
                const firstSection = newCandidate.sections.find(section => section.step === 1);
                dispatch(updateCandidateStore(newCandidate));
                history.push(getRulesPageUrl(firstSection.sectionName, firstSection.sectionId));
            })
            .catch((error) => {
                setLoading(false);
                toast.error(error.message, {position: toast.POSITION.TOP_RIGHT})
            })
    }

    return (
        candidate ?
            <Fragment>
                <Header step={0} sections={candidate.sections}/>
                <div className="mb-4">
                    <Form onSubmit={(event) => handleSubmit(event)} className="col-md-8 offset-md-2">
                        <Row className="paper-card mb-4">
                            <Col md={6}>
                                <Form.Group>
                                    <Form.Label>Informations</Form.Label>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Control
                                        type="text"
                                        placeholder="Prénom"
                                        id="firstName"
                                        value={fields.firstName}
                                        required
                                        onChange={handleChange}
                                    />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Control
                                        type="text"
                                        placeholder="Nom"
                                        id="lastName"
                                        value={fields.lastName}
                                        required
                                        onChange={handleChange}

                                    />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Control
                                        type="tel"
                                        placeholder="Téléphone"
                                        pattern="[0-9]{10}"
                                        id="phone"
                                        value={fields.phone}
                                        required
                                        onChange={handleChange}

                                    />
                                </Form.Group>
                                {candidate.status === 'trainee' ?
                                    <Form.Group>
                                        <Form.Control
                                            type="text"
                                            id="school"
                                            placeholder="Ecole"
                                            value={fields.school}
                                            required
                                            onChange={handleChange}
                                        />
                                    </Form.Group>
                                    : null
                                }
                            </Col>

                            <Col md={6}>
                                <Form.Group>
                                    <Form.Label>Pièces Jointes</Form.Label>
                                </Form.Group>
                                <Form.Group className="text-left">
                                    <UploadFile
                                        id="photo"
                                        label="Photo (.jpg, .png)"
                                        handleChange={handleChange}
                                    />
                                </Form.Group>
                                <Form.Group className="text-left">
                                    <UploadFile
                                        id="cv"
                                        label="CV (.pdf)"
                                        handleChange={handleChange}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Button
                            variant="primary"
                            size="lg"
                            type="submit"
                            disabled={loading}
                        >
                            {loading &&
                            <Spinner
                                className="mr-2"
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />}
                            Enregistrer
                        </Button>
                    </Form>
                </div>
            </Fragment> :
            loader
    )
}