import React, {useEffect, useState} from 'react';
import Image from 'react-bootstrap/Image';
import Form from 'react-bootstrap/Form';
import UploadFile from 'components/profile/UploadFile';
import {toast} from 'react-toastify';
import {getUrlImage, requestUploadImage} from 'service/question';
import uploadFile from 'service/utils/uploadFile';
import {Spinner} from 'react-bootstrap';

export default function QuestionImageConfig(props) {

    const [loading, setLoading] = useState(false);

    const [image, setImage] = useState(null);

    useEffect(() => {
        //Si il n'y a pas de clé, on supprime l'image
        if (!props.keyImage) {
            setImage(null);
        } else {
            getImage();
        }
    }, [props.keyImage]);

    const getImage = () => {
        if (props.keyImage) {
            getUrlImage(props.keyImage)
                .then(result => {
                    setImage(result.data);
                })
                .catch(error => {
                    toast.error(error.message, {position: toast.POSITION.TOP_RIGHT})
                })
        }
    };

    /**
     * @desc Gère l'ajout d'une nouvelle image par l'utilisateur
     */
    const handleChange = event => {
        // On active le loader
        setLoading(true);
        // fichier de l'input
        const file = event.target.files[0];

        if (file) {
            event.target.value = null;
            // Data à envoyer à la requête
            const data = {
                name: `${props.sectionId}/${file.name}`,    // Clé du fichier
                type: file.type,                           // Type du fichier
            };

            //Demande d'un lien pour envoyer l'image au bucket s3
            requestUploadImage(props.sectionId, data)
                .then((response) => {
                    //Upload de l'image dans le s3
                    uploadFile(response.data.url, file)
                        .then(() => {
                            props.handleImageChange(response.data.key);
                            setLoading(false);
                        })
                        .catch((e) => {
                            setLoading(false);
                            toast.error('Erreur lors de l\'upload de l\'image', {position: toast.POSITION.TOP_RIGHT});
                        })
                });
        } else {
            toast.error('Image introuvable', {position: toast.POSITION.TOP_RIGHT});
        }
    }

    return (
        <div className="mb-2 flex-column justify-content-center">
            {/*Si image est null (pas d'image), le composant Image ne s'affiche pas*/}
            {image && <Image src={image} rounded fluid/>}

            <Form.Group className="text-left mt-3">
                <UploadFile
                    id="photo"
                    className="image-upload"
                    required={false}
                    label={`${image ? "Modifier l'" : "Ajouter une "}image (.jpg, .png)`}
                    handleChange={handleChange}
                />
                {
                    loading &&
                    <Spinner
                        className="mr-2"
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    />
                }
            </Form.Group>
        </div>
    )
}