import React, {useEffect, useState} from 'react'
import logo from 'assets/logo2019.svg'
import './Welcome.scss'
import qs from 'qs'
import {Button, Container, Form, Spinner} from 'react-bootstrap'
import {candidateAuth} from 'service/candidate'
import {SESSION_ID, TOKEN} from 'data/constant'
import {toast} from 'react-toastify'
import {PAGES} from 'enums/pageUrl'

const Welcome = ({history, location}) => {

    const [loading, setLoading] = useState(false);
    const [isInvalid, setIsInvalid] = useState(null);
    const [candidateId, setCandidateId] = useState(null);
    const [field, setField] = useState('');
    const [errorMessage, setErrorMessage] = useState(null);

    useEffect(() => {
        if (location.search && !candidateId) {
            const {candidateId} = qs.parse(location.search, {ignoreQueryPrefix: true});
            setCandidateId(candidateId);
            sessionStorage.setItem(SESSION_ID, candidateId);
        }
    }, [location.search, candidateId]);

    const handleBegin = (event) => {
        event.preventDefault();
        setLoading(true);
        candidateAuth(candidateId, parseInt(field))
            .then((result) => {
                setLoading(false);
                sessionStorage.setItem(TOKEN, result.data['token']);
                history.push(PAGES.FORM_USER);
            })
            .catch(error => {
                setLoading(false);
                setIsInvalid(true);
                if (error.response) {
                    if (error.response.status === 401) {
                        setErrorMessage(error.response.data.message);
                        setIsInvalid(true);
                    } else {
                        toast.error(
                            error.response.data.message,
                            {position: toast.POSITION.TOP_RIGHT}
                        );
                    }
                } else {
                    toast.error(
                        error.message + ":  ce code n'est pas valide",
                        {position: toast.POSITION.TOP_RIGHT}
                    );
                }
            });
    };

    return (
        <div className="app-content">
            <Container fluid="md">
                <img src={logo} className="app-logo" alt="logo"/>
                <h1 className="m-5">Bienvenue sur l'application de test de Coreoz</h1>
                <p>Entrez le code reçu par mail :</p>
                <Form onSubmit={handleBegin}>
                    <section className="row justify-content-center m-4">
                        <div className="col-sm-4 col-md-3">
                            <Form.Control
                                size="lg"
                                type="password"
                                placeholder="Code"
                                maxLength={6}
                                pattern="[0-9]{6}"
                                isInvalid={isInvalid}
                                required
                                onChange={(event) => setField(event.target.value)}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errorMessage}
                                <br/>
                                Veuillez vérifier le code reçu ou envoyer un mail à
                                <a href="interview@coreoz.com"> interview@coreoz.com</a>.
                            </Form.Control.Feedback>
                        </div>
                    </section>
                    <Button
                        variant="primary"
                        size="lg"
                        type="submit"
                        disabled={!(field.length === 6)}
                    >
                        {loading &&
                        <Spinner
                            as="span"
                            className="mr-2"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />
                        }
                        Commencer
                    </Button>
                </Form>
            </Container>
        </div>
    )
};

export default Welcome;