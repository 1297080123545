import React, {useEffect, useState} from 'react'
import {Button, Col, Form, Row} from 'react-bootstrap';
import {useFormFields} from 'utils/hooksForms';
import Datetime from 'react-datetime';
import {getSections} from 'service/question';
import {toast} from 'react-toastify';
import {STATUS} from 'data/constant';
import ListGroup from 'react-bootstrap/ListGroup';
import './CandidateForm.scss';
import {getFormattedDuration} from 'utils/JSUtils';

export default function CandidateForm(props) {

    const [sections, setSections] = useState([]);
    const [selectedSectionIdList, setSelectedSectionIdList] = useState([]);
    const [totalTime, setTotalTime] = useState(0);

    const [fields, setFields] = useFormFields({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        school: '',
        promotionYear: '',
        status: '',
        sections: [],
        agency: 'Paris'
    });

    useEffect(() => {
        getSections()
            .then(response => {
                setSections(response.data['Items']);
            })
            .catch(error => {
                toast.error(error.message, {position: toast.POSITION.TOP_RIGHT});
            })
    }, []);

    const onChangeYear = event => {
        let date = new Date(event._d);
        fields.promotionYear = date.getFullYear();
    }

    /**
     * @desc Ajoute/Supprime une section dans la liste de sections du candidat
     * @param id Identifiant de la section
     */
    const toggleSection = id => {
        const section = sections.find(section => section.sectionId === id);
        const time = parseInt(section.time) || 0;
        //Si la section est déjà dans la liste, on la supprime
        if (selectedSectionIdList.includes(id)) {
            let newSelectedSectionIdList = [...selectedSectionIdList];
            newSelectedSectionIdList.splice(newSelectedSectionIdList.indexOf(id), 1);
            setSelectedSectionIdList(newSelectedSectionIdList);
            //Et on met à jour le temps total des sections chronométrée
            setTotalTime(totalTime - time);
        } else {
            //Sinon on l'ajoute
            setSelectedSectionIdList([...selectedSectionIdList, id]);
            //Et on met à jour le temps total des sections chronométrée
            setTotalTime(totalTime + time);
        }
    }

    const handleSubmit = event => {
        event.preventDefault();
        fields.sections = [...selectedSectionIdList];

        if (!fields.status) {
            toast.warn('Aucun statut n\'est sélectionné pour le candidat', {position: toast.POSITION.TOP_RIGHT});
        } else if (fields.sections.length === 0) {
            toast.warn('Aucun test n\'est sélectionné pour le candidat', {position: toast.POSITION.TOP_RIGHT});
        } else {
            props.sendFormData(fields);
        }
    };

    const sectionSelectList = () => {
        if (Array.isArray(sections) && sections.length > 0) {
            return sections.map((section, index) =>
                <Form.Check
                    custom
                    inline
                    className="text-left"
                    name="section"
                    label={section.label}
                    value={section.sectionId}
                    id={section.sectionId}
                    key={index}
                    checked={selectedSectionIdList.includes(section.sectionId)}
                    onChange={() => toggleSection(section.sectionId)}
                />
            );
        } else {
            return null;
        }
    };

    const statusRadioList = () => {
        return Object.keys(STATUS).map(key =>
            <Form.Check
                custom
                inline
                className="text-left"
                name="status"
                label={STATUS[key]}
                value={key}
                type="radio"
                id={key}
                key={key}
                checked={fields["status"] === key}
                onChange={setFields}
            />
        )
    }

    return (
        <div className="admin">
            <Form className="candidates-home candidate-form" onSubmit={handleSubmit}>
                <div className="mb-4">
                    <h2>Nouveau <span className="accentuated-title">candidat</span></h2>
                    <Row>
                        <Col md={6}>
                            <div className="form-header">Informations personnelles</div>
                            <Form.Group>
                                <Form.Control
                                    type="text"
                                    placeholder="Prénom *"
                                    name="firstName"
                                    value={fields["firstName"]}
                                    required
                                    onChange={setFields}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Control
                                    type="text"
                                    placeholder="Nom *"
                                    name="lastName"
                                    value={fields["lastName"]}
                                    required
                                    onChange={setFields}

                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Control
                                    type="tel"
                                    placeholder="Téléphone"
                                    name="phone"
                                    value={fields["phone"]}
                                    maxLength={10}
                                    pattern="[0-9]{10}"
                                    onChange={setFields}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Control
                                    type="email"
                                    placeholder="Email *"
                                    name="email"
                                    value={fields["email"]}
                                    required
                                    onChange={setFields}
                                />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <div className="form-header">Type de contrat</div>
                            <div key="inline-radio" className="text-left mb-3">
                                {
                                    statusRadioList()
                                }
                            </div>
                            <Form.Group>
                                <Form.Control
                                    as="select"
                                    placeholder="Agence"
                                    name="agency"
                                    value={fields["agency"]}
                                    onChange={setFields}>
                                    <option value="Paris">Paris</option>
                                    <option value="Lyon">Lyon</option>
                                </Form.Control>
                            </Form.Group>
                            <Form.Group>
                                <Form.Control
                                    type="text"
                                    name="school"
                                    value={fields["school"]}
                                    placeholder="École"
                                    onChange={setFields}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Datetime dateFormat="YYYY" inputProps={{placeholder: 'Année de promotion'}}
                                          name="promotionYear" value={fields["year"]} onChange={onChangeYear}/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="form-header">Tests</div>
                            <ListGroup>
                                {
                                    sectionSelectList()
                                }
                            </ListGroup>
                        </Col>
                    </Row>
                    {
                        totalTime > 0 &&
                        <Row className="flex-row align-items-center justify-content-center mt-3">
                            <Col>
                                <p>Durée total des tests chronométrés: <strong> {getFormattedDuration(totalTime)}</strong>
                                </p>
                            </Col>
                        </Row>
                    }
                </div>
                <Button
                    variant="primary"
                    className="margin-auto"
                    type="submit"
                >
                    Enregistrer et envoyer le mail
                </Button>
            </Form>
        </div>
    )
}