import React from 'react'
import {Button, Spinner} from 'react-bootstrap'
import PictoSignification from './PictoExplication'

function RulesContainer(props) {

    const {header, content, method, loading, isLogicTest} = props;

    return (
        <div className="paper-card col-8 offset-2">
            <h1>{header}</h1>
            <div className="text-justify" dangerouslySetInnerHTML={{__html: content}}/>
            <div className="text-justify">
                {
                    isLogicTest ?
                        <PictoSignification/>
                        : null
                }
            </div>
            <Button
                variant="primary"
                size="lg"
                disabled={loading}
                onClick={method}
            >
                {loading &&
                <Spinner
                    className="mr-2"
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                />
                }

                Commencer
            </Button>
        </div>
    )
}

export default RulesContainer;