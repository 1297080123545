import React from 'react'
import {withRouter} from 'react-router-dom'
import {Route, Switch} from 'react-router-dom'
import NotFound from 'components/error/NotFound'
import Welcome from 'components/welcome/Welcome'
import FormUser from 'components/profile/FormUser'
import PerceptionQuestion from 'components/perception/PerceptionQuestion'
import PerceptionValidation from 'components/perception/PerceptionValidation'
import LogicTestQuestion from 'components/logicTest/LogicTestQuestion'
import LogicTestValidation from 'components/logicTest/LogicTestValidation'
import Candidates from 'components/admin/candidates/Candidates'
import NewCandidate from 'components/admin/candidates/create/NewCandidate'
import InfoCandidate from 'components/admin/candidates/info/InfoCandidate'
import HomePage from 'components/admin/HomePage'
import ProtectedRoute from 'components/ProtectedRoute'
import ConfigurationPage from 'components/admin/configuration/ConfigurationPage';
import SectionPage from 'components/admin/configuration/sections/SectionPage';
import RulesPage from 'components/rules/RulesPage';
import {PAGES} from 'enums/pageUrl';

const Routes = () => {
    return (
        <Switch>
            <Route exact path={PAGES.WELCOME} component={Welcome}/>
            <Route path={PAGES.FORM_USER} component={FormUser}/>
            <Route path={PAGES.RULES + ":sectionName/:sectionId"} component={RulesPage}/>
            <Route path={PAGES.PERCEPTION_QUESTION + ":id"} component={PerceptionQuestion}/>
            <Route path={PAGES.PERCEPTION_VALIDATION} component={PerceptionValidation}/>
            <Route exact path={PAGES.SKILL_TEST + ":sectionName/question/:id"} component={LogicTestQuestion}/>
            <Route exact path={PAGES.SKILL_TEST + ":sectionName/validation"} component={LogicTestValidation}/>
            <Route exact path={PAGES.HOME_PAGE_ADMIN} component={HomePage}/>
            <ProtectedRoute exact path={PAGES.CANDIDATES_ADMIN} component={Candidates}/>
            <ProtectedRoute exact path={PAGES.NEW_CANDIDATE_ADMIN} component={NewCandidate}/>
            <ProtectedRoute exact path={PAGES.INFO_CANDIDATES_ADMIN + ":id"} component={InfoCandidate}/>
            <ProtectedRoute exact path={PAGES.CONFIG_PAGE_ADMIN} component={ConfigurationPage}/>
            <ProtectedRoute exact path={PAGES.SECTION_CONFIG_PAGE_ADMIN + ":sectionName?/:id?"}
                            component={SectionPage}/>
            {/* Catch all unmatched routes */}
            <Route component={NotFound}/>
        </Switch>
    )
};
export default withRouter(Routes)
