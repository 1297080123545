import React, {useEffect, useState} from 'react';
import Form from 'react-bootstrap/Form';
import {Button} from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {faTrash} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import QuestionImageConfig from './QuestionImageConfig';
import DeleteButton from 'components/deleteButton/DeleteButton';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';

export default function LogicTestQuestionConfig(props) {

    const [header, setHeader] = useState(props.question.header);
    const [imageKey, setImageKey] = useState(props.question.image);
    //Hook pour les choix des question de type QCM
    const [choices, setChoices] = useState(props.question.choices);
    //Hook pour les réponses aux questions ouvertes
    const [openAnswer, setOpenAnswer] = useState(props.question.answer);

    useEffect(() => {
        setHeader(props.question.header);
        setChoices(props.question.choices);
        setOpenAnswer(props.question.answer);
        setImageKey(props.question.image);
    }, [props.question]);

    /**
     * @desc Modifie la valeur de l'attribut d'une réponse de type QCM
     * (réponse de la forme {label: labelValue, correct: true/false}
     * @param index - index de la réponse
     * @param name - nom de l'attribut de la réponse
     * @param value - valeur de l'attribue de la réponse
     */
    const setQCMAnswer = (index, name, value) => {
        let newChoices = [...choices];
        newChoices[index][name] = value;
        setChoices(newChoices);
    }

    //Ajoute une réponse à la question
    const addChoice = () => {
        if (choices) {
            let newChoices = [...choices, {label: '', correct: false}];
            setChoices(newChoices);
        }
    }

    //Supprime une réponse de la question
    const removeAnswer = (index) => {
        let newChoices = [...choices];
        newChoices.splice(index, 1)
        setChoices(newChoices);
    }

    //Construit la question et l'envoie au composant parent
    const handleSubmit = event => {
        event.preventDefault();
        let updatedQuestion;
        if (openAnswer) {
            updatedQuestion = {
                header,
                answer: openAnswer,
                image: imageKey
            }
        } else {
            updatedQuestion = {
                header,
                choices,
                image: imageKey
            }
        }
        props.addOrUpdateQuestion(updatedQuestion);
        setImageKey(null);
    }

    /**
     * @desc Construit un composant pour chaque réponse de la question
     * @param answerList - liste de réponse
     */
    const QCMAnswerFormList = (answerList) => {
        //Si il y a déjà des réponses, on renvoie les input avec les valeurs existantes
        if (Array.isArray(answerList) && answerList.length > 0) {
            return answerList.map((answer, index) =>
                <Row key={index} className="answer-row align-items-end">
                    <Col md={5}>
                        <Form.Group>
                            <Form.Label>Réponse</Form.Label>
                            <Form.Control size="sm"
                                          as="textarea"
                                          rows={answer.label ? Math.trunc(answer.label.length / 55) + 1 : 1}
                                          name="label"
                                          value={answer.label}
                                          onChange={e => setQCMAnswer(index, e.target.name, e.target.value)}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={5}>
                        <Form.Group className="mb-3">
                            <Form.Check
                                name="correct"
                                type="checkbox"
                                label="Bonne réponse"
                                checked={answer.correct}
                                onChange={e => setQCMAnswer(index, e.target.name, e.target.checked)}/>
                        </Form.Group>
                    </Col>
                    <Col md={2} className="mb-3">
                        <FontAwesomeIcon className="removeAnswerButton" icon={faTrash} size="sm"
                                         onClick={() => removeAnswer(index)}/>
                    </Col>
                </Row>
            );
        } else {
            //Sinon on ajoute une réponse vide
            addChoice();
        }
    };

    const openQuestionForm = () => {
        return (
            <>
                <Form.Group>
                    <Form.Group className="d-flex align-items-center">
                        <Form.Check
                            name="requiresCorrection"
                            type="checkbox"
                            label="Nécessite un correcteur"
                            checked={openAnswer ? openAnswer.requiresCorrection : false}
                            onChange={e => setOpenAnswer({
                                ...openAnswer,
                                requiresCorrection: e.target.checked
                            })}
                        />
                        <OverlayTrigger trigger={["hover", "focus"]} placement="right" overlay={openQuestionPopover}>
                            <FontAwesomeIcon className="ml-2" icon="question-circle"/>
                        </OverlayTrigger>
                    </Form.Group>
                </Form.Group>
                {
                    openAnswer && !openAnswer.requiresCorrection &&
                    <Form.Group>
                        <Form.Label>Réponse</Form.Label>
                        <Form.Control size="sm" type="text" name="answer"
                                      value={openAnswer ? openAnswer.value : ''}
                                      onChange={e => setOpenAnswer({
                                          ...openAnswer,
                                          value: e.target.value
                                      })}
                        />
                    </Form.Group>
                }
            </>
        )
    }

    const openQuestionPopover = (
        <Popover id="popover-basic">
            <Popover.Title as="h3">Correction nécessaire</Popover.Title>
            <Popover.Content className="text-justify">
                Indique si cette question n'a qu'une réponse possible ou si sa correction nécessite l'intervention d'un
                correcteur.
            </Popover.Content>
        </Popover>
    );

    return (
        <div id="question-form">
            <Form onSubmit={handleSubmit}>
                <Form.Group>
                    <Form.Label>Intitulé de la question</Form.Label>
                    <Form.Control
                        size="md"
                        as="textarea"
                        //Adaptation approximative du nombre de lignes en fonction du nombre de caractère
                        rows={header ? Math.trunc(header.length / 100) + 1 : 1}
                        name="header"
                        value={header || ''}
                        onChange={e => setHeader(e.target.value)}
                    />
                </Form.Group>
                <Form.Group>
                    <QuestionImageConfig
                        keyImage={imageKey}
                        sectionId={props.sectionId}
                        handleImageChange={(key) => setImageKey(key)}
                    />
                </Form.Group>
                {
                    //Si la question est composée de choix (QCM) on affiche la liste de choix
                    props.question.choices ?
                        QCMAnswerFormList(choices)
                        //Sinon c'est une question ouverte: on affiche un input text pour la réponse
                        :
                        openQuestionForm(openAnswer)
                }
                {
                    //Bouton d'ajout de réponse uniquement si c'est une question de type QCM
                    props.question.choices &&
                    <Button
                        className="my-2"
                        variant="outline-secondary"
                        size="sm"
                        onClick={addChoice}
                    >
                        Ajouter une réponse
                    </Button>
                }
                <Button
                    className={`mr-2 ${props.question.choices ? 'ml-2' : ''}`}
                    variant="primary"
                    size="sm"
                    type="submit"
                >
                    Enregistrer
                </Button>
                {
                    //Si l'index est null, la question n'est pas encore dans la section donc on ne peut pas la supprimer
                    props.index !== null &&
                    <DeleteButton buttonText="Supprimer la question" handleDelete={props.deleteQuestion} size={"sm"}
                                  placement={"top"}/>
                }
            </Form>
        </div>
    )
}