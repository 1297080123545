import React from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import './Document.scss'
import {faFileImage, faFilePdf} from '@fortawesome/free-solid-svg-icons'
import {TYPE_DOCUMENT} from 'data/constant'
import {getUrlFile} from 'service/admin';

export default function Document(props) {

    const {filename, type, keyFile} = props;

    const openDocument = () => {
        getUrlFile(keyFile)
            .then(result => {
                window.open(result.data);
            })
    };

    return (
        <div className="d-flex align-items-center document" onClick={openDocument}>
            <div className="file d-flex justify-content-center" >
                {type === TYPE_DOCUMENT.PDF &&
                <FontAwesomeIcon className="icon icon-pdf" icon={faFilePdf} size="3x"/>
                }
                {type === TYPE_DOCUMENT.IMAGE &&
                <FontAwesomeIcon className="icon icon-image" icon={faFileImage} size="3x"/>
                }
            </div>
            <div className="filename">{filename}</div>
        </div>
    )
}