import React, {Fragment, useEffect, useState} from 'react';
import NavigationBar from '../navbar/NavigationBar';
import {Button, Spinner} from 'react-bootstrap';
import {toast} from 'react-toastify';
import './ConfigurationPage.scss';
import SectionIconButton from './sections/SectionIconButton';
import {getSections} from 'service/question';
import useLoader from 'components/loader/useLoader';
import {PAGES} from "../../../enums/pageUrl";


export default function ConfigurationPage(props) {

    const [sections, setSections] = useState(null);

    // Hook pour afficher un composant loader
    const [loader, showLoader, hideLoader] = useLoader();

    useEffect(() => {
        showLoader()
        getSections()
            .then(response => {
                setSections(response.data['Items']);
                hideLoader()
            })
            .catch(error => {
                toast.error(error.message, {position: toast.POSITION.TOP_RIGHT});
                hideLoader()
            })
    }, []);

    const displaySection = (section, index) => {
        return <div key={index} className="test-section">
            {
                section ?
                    <SectionIconButton
                        text={section.label}
                        icon={section.icon}
                        sectionName={section.sectionName}
                        sectionId={section.sectionId}
                    />
                    :
                    <Spinner
                        className="m-auto"
                        variant="primary"
                        as="span"
                        animation="border"
                        size="md"
                        role="status"
                        aria-hidden="true"
                    />
            }
        </div>
    }

    const sectionIconButtonList = (sectionList) => {
        if (Array.isArray(sectionList) && sectionList.length > 0) {
            const chunk1 = sectionList.slice(0, sectionList.length / 2);
            const chunk2 = sectionList.slice(sectionList.length / 2, sectionList.length);

            return (<div className="d-flex">
                    <div className="d-flex flex-column flex-grow-1 mr-2">
                        {chunk1.map((section, index) => displaySection(section, index))}
                    </div>
                    <div className="d-flex flex-column flex-grow-1 ml-2">
                        {chunk2.map((section, index) => displaySection(section, index))}
                    </div>
            </div>
            );
        } else {
            return null;
        }
    };

    return (
        <Fragment>
            <NavigationBar/>
            <div className="admin candidates-home">
                <div className="candidates-header d-flex justify-content-between align-items-center">
                    <h2>Configuration des <span className="accentuated-title">tests</span></h2>
                    <Button
                        variant="primary"
                        onClick={() => props.history.push((PAGES.SECTION_CONFIG_PAGE_ADMIN + 'new-section'))}
                    >
                        Ajouter un test
                    </Button>
                </div>

                {
                    sectionIconButtonList(sections)
                }
                {
                    loader
                }
            </div>
        </Fragment>
    )
}