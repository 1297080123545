import React from 'react'
import {Button, Nav, Navbar} from 'react-bootstrap'
import {withRouter} from 'react-router-dom'
import {TOKEN} from 'data/constant';
import {PAGES} from 'enums/pageUrl';

function NavigationBar(props) {

    const handleLogout = () => {
        sessionStorage.removeItem(TOKEN);
        props.history.push(PAGES.HOME_PAGE_ADMIN);
    }

    const getActiveClassName = pathName => {
        return props.location.pathname === pathName ? 'active' : '';
    }

    return (
        <Navbar collapseOnSelect expand="lg" variant="dark" className="navbar-menu">
            <Navbar.Brand href={PAGES.CANDIDATES_ADMIN}>
                Steam Interview Manager
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="mr-auto">
                    <Nav.Link href={PAGES.CANDIDATES_ADMIN}
                              className={getActiveClassName(PAGES.CANDIDATES_ADMIN)}>Candidats</Nav.Link>
                    <Nav.Link href={PAGES.CONFIG_PAGE_ADMIN}
                              className={getActiveClassName(PAGES.CONFIG_PAGE_ADMIN)}>Configuration</Nav.Link>
                </Nav>
                <Nav>
                    <Nav.Link component={Button} onClick={handleLogout}>Déconnexion</Nav.Link>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    )
}

export default withRouter(NavigationBar);
