import {del, get, post} from './utils/httpRequest'
import {ACCESS_TOKEN, TOKEN} from 'data/constant'

import config from 'config';

const API_URL = config.apiGateway.URL;
const COGNITO_USER_INFO_URL = config.cognito.USER_INFO_URL;

function defaultHeaders(tokenType) {
    switch (tokenType) {
        case ACCESS_TOKEN:
            return {Authorization: `Bearer ${sessionStorage.getItem(ACCESS_TOKEN)}`};
        default:
            return {Authorization: `Bearer ${sessionStorage.getItem(TOKEN)}`};
    }
}

/**********************************************************
 ******************** Authentification ********************
 **********************************************************/

export async function authenticate() {
    return get(API_URL + '/admin/authentication', defaultHeaders());
}

export async function getConnectedUserInfo() {
    return get(COGNITO_USER_INFO_URL, defaultHeaders(ACCESS_TOKEN))
}

/**********************************************************
 *********************** Candidats ************************
 **********************************************************/

export async function createCandidate(candidateInfo) {
    return post(API_URL + '/admin/candidates', defaultHeaders(), buildCreateCandidateRequest(candidateInfo));
}

export async function updateCandidate(candidateId, candidateData) {
    return post(API_URL + `/admin/candidates/${candidateId}`, defaultHeaders(), candidateData);
}

export async function deleteCandidate(candidateId) {
    return del(API_URL + `/admin/candidates/${candidateId}`, defaultHeaders());
}

export async function getCandidateById(candidateId) {
    return get(API_URL + `/admin/candidates/${candidateId}`, defaultHeaders());
}

export async function getCandidates(filters, page) {
    return get(API_URL + `/admin/candidates`, defaultHeaders(), buildQueryParams(filters, page));
}

export async function getUrlFile(keyFile) {
    return get(API_URL + `/admin/candidates/file`, defaultHeaders(), {keyFile: keyFile});
}

export async function sendNewTest(candidateId, sections) {
    return post(API_URL + `/admin/candidates/${candidateId}/sections`, defaultHeaders(), sections);
}

function buildCreateCandidateRequest(candidateInfo) {
    const {email, firstName, lastName, phone, status, school, promotionYear, sections, agency} = candidateInfo;
    return {
        email: email,
        firstName: firstName,
        lastName: lastName,
        phone: phone,
        school: school,
        promotionYear: promotionYear,
        status: status,
        sections: sections,
        comments: [],
        agency: agency
    }
}

function buildQueryParams(filters, page) {
    let queryParams = {};
    if (filters.name) {
        queryParams.name = filters.name;
    }
    if (filters.school) {
        queryParams.school = filters.school;
    }
    if (filters.agency) {
        queryParams.agency = filters.agency;
    }
    if (filters.order) {
        queryParams.order = filters.order;
    }
    let status = '';
    for (let [key, value] of Object.entries(filters.status)) {
        if (value) {
            status === '' ? status += key : status += ',' + key;
        }
    }
    if (status) {
        queryParams.status = status;
    }
    if (filters.promotionYearList && filters.promotionYearList.length > 0) {
        queryParams.promotionYear = filters.promotionYearList.join(",");
    }
    queryParams.page = page || 1;
    return queryParams
}
