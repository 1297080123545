import React from 'react'
import logo from 'assets/logo2019.svg'
import './Header.scss'
import { Col } from 'react-bootstrap'

export default function Header(props) {

    return (
        <div className="header p-4">
            <Col md={2} sm={12}>
                <img src={logo} className="app-logo" alt="logo" />
            </Col>
            <Col md={8} sm={12} className="d-flex justify-content-between">
                {[{label: 'Profil'}, ...props.sections].map((section, index) => (
                        <div className="header-item">
                            <div key={index} className={props.step === index ? "circle active" : "circle"}> <p>{index + 1}</p></div>
                            <div className="circle-label">{section.label}</div>
                        </div>
                    )
                )}
            </Col>
        </div>
    )
}