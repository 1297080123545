import React, {Fragment, useState} from 'react'
import {Button, Row, Spinner} from 'react-bootstrap'
import NavigationBar from '../navbar/NavigationBar'
import Polaroid from './polaroid/Polaroid'
import SearchCandidate from './search/SearchCandidate'
import {getAdminCandidatePage, PAGES} from 'enums/pageUrl'
import './Candidates.scss';
import {faAngleLeft, faAngleRight} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import EmptyListAlert from 'components/info/EmptyListAlert';

const PAGE_SIZE = 20;

export default function Candidates(props) {
    const [candidates, setCandidates] = useState([]);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);

    // Hook pour afficher un composant loader
    const [isRequestPending, setIsRequestPending] = useState(false);

    const getTotalPage = () => {
        return Math.floor(total / PAGE_SIZE) + 1;
    }

    const previousPageEnabled = () => {
        return page > 1;
    }

    const nextPageEnabled = () => {
        return page < getTotalPage();
    }

    return (
        <Fragment>
            <NavigationBar/>
            <div className="admin">
                <div className="candidates-home">
                    <h2>Recherche de <span className="accentuated-title"> candidats</span></h2>
                    <div className="d-flex justify-content-between align-items-center">
                        <SearchCandidate page={page} setPage={setPage}
                                         setCandidates={setCandidates}
                                         setTotal={setTotal}
                                         setIsRequestPending={setIsRequestPending}/>
                        <Button
                            variant="primary"
                            onClick={() => props.history.push(PAGES.NEW_CANDIDATE_ADMIN)}
                        >
                            Ajouter un candidat
                        </Button>
                    </div>
                    <div className="candidate-list-col">
                        {isRequestPending === false ?
                            <div className="candidate-list">
                                {!!candidates && candidates.length > 0 ? candidates.map((chunk) => (
                                    <div className="candidate-row">
                                        {chunk.map((candidate, index) => (
                                            candidate.isEmpty ?
                                                <Polaroid key={index} isEmpty/>
                                                :
                                                <Polaroid
                                                    key={index}
                                                    fullName={candidate.firstName + ' ' + candidate.lastName}
                                                    keyPhoto={candidate.photo}
                                                    testDate={candidate.testDate}
                                                    link={getAdminCandidatePage(candidate.candidateId)}
                                                />
                                        ))}
                                    </div>
                                )) : <EmptyListAlert/>
                                }
                            </div>
                            :
                            <Row className="d-flex align-items-center justify-content-center mt-5 spinner-height">
                                <Spinner animation="border" variant="primary"/>
                            </Row>
                        }
                        {getTotalPage() > 1 &&
                            <>
                                <div className="pagination-bottom">
                                    <div className="pagination">
                                        <Button variant="outline-secondary mr-3"
                                                disabled={!previousPageEnabled()}
                                                onClick={() => setPage(page - 1)}
                                        >
                                            <FontAwesomeIcon icon={faAngleLeft}/>
                                        </Button>
                                        <span>Page {page} sur {getTotalPage()}</span>
                                        <Button variant="outline-secondary ml-3"
                                                disabled={!nextPageEnabled()}
                                                onClick={() => setPage(page + 1)}
                                        >
                                            <FontAwesomeIcon icon={faAngleRight}/>
                                        </Button>
                                    </div>
                                    <div className="pagination-count">
                                        <span>{`${total} candidats`}</span>
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
