export const PAGES = {
    WELCOME: '/',
    FORM_USER: '/informations-utilisateurs',
    RULES: '/informations-test/',
    PERCEPTION_QUESTION: '/auto-perception/question/',
    PERCEPTION_VALIDATION: '/auto-perception/validation',
    SKILL_TEST: '/skill-test/',
    HOME_PAGE_ADMIN: '/admin',
    CANDIDATES_ADMIN: '/admin/candidats',
    NEW_CANDIDATE_ADMIN: '/admin/candidats/creation',
    INFO_CANDIDATES_ADMIN: '/admin/candidats/',
    CONFIG_PAGE_ADMIN: '/admin/configuration',
    SECTION_CONFIG_PAGE_ADMIN: '/admin/section/'
};

/**
 * @desc Returns the right link for the given question number of the perception test
 * @param questionNumber
 */
export function getPerceptionQuestionUrl(questionNumber) {
    return PAGES.PERCEPTION_QUESTION + questionNumber;
}

/**
 * @desc Returns the right link for the given section
 * @param sectionId
 * @param sectionName
 */
export function getRulesPageUrl(sectionName, sectionId) {
    return PAGES.RULES + sectionName + "/" + sectionId;
}

/**
 * @desc Returns the right link for the given section
 * @param sectionId
 * @param sectionName
 */
export function getSectionPageUrl(sectionName, sectionId) {
    return sectionId ? (PAGES.SECTION_CONFIG_PAGE_ADMIN + sectionName + "/" + sectionId) : (PAGES.SECTION_CONFIG_PAGE_ADMIN + sectionName);
}

/**
 * @desc Returns the right link for the logic test
 * @param sectionName
 * @param page - logic test page ('regles', 'validation' or 'question')
 * @param questionNumber
 */
export function getSkillTestUrl(sectionName, page, questionNumber) {
    let url = PAGES.SKILL_TEST + sectionName;
    switch (page) {
        case 'validation':
            return url += "/" + page;
        case 'question':
            //Returns null if questionNumber is null, concatenate the url and the question number otherwise
            return questionNumber != null ? (url += "/question/" + questionNumber) : null;
        default:
            return null;
    }
}

/**
 * @desc Returns the right link for the given candidate id
 * @param candidateId
 */
export function getAdminCandidatePage(candidateId) {
    return PAGES.INFO_CANDIDATES_ADMIN + candidateId;
}