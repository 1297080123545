import React, {Fragment, useEffect, useState} from 'react';
import Header from 'components/header/Header';
import RulesContainer from './RulesContainer';
import {getCandidateAndSection, updateCandidate} from 'service/candidate';
import {updateCandidate as updateCandidateStore} from 'store/actions/actionsCandidate';
import {getPerceptionQuestionUrl, getSkillTestUrl} from 'enums/pageUrl';
import {useDispatch, useSelector} from 'react-redux';
import {CURENT_SECTION_ID} from 'data/constant';
import useLoader from 'components/loader/useLoader';
import FinishedTest from "../finishedTest/FinishedTest";

export default function RulesPage({history, match}) {

    const dispatch = useDispatch();
    const {params: {sectionId}} = match;

    // Hook pour afficher un composant loader
    const [loader, showLoader, hideLoader] = useLoader();
    // Booléen pour le composant des règles de test
    const [loading, setLoading] = useState(false);

    const [candidate, setCandidate] = useState(useSelector(state => state.candidate));
    const [section, setSection] = useState(null);

    useEffect(() => {
        if (!candidate || !section) {
            showLoader();
            getCandidateAndSection(sectionId)
                .then((result) => {
                    setCandidate(result.candidate);
                    setSection(result.section);
                    hideLoader();
                })
        }
    }, [candidate]);

    useEffect(() => {
        if (candidate && sectionId && candidate.sections) {
            setSection(candidate.sections.find(section => section.sectionId === sectionId));
        }
    }, [candidate, sectionId]);

    const initializeStartTest = () => {
        setLoading(true);
        localStorage.setItem(CURENT_SECTION_ID, sectionId);
        let updatedSection = section;
        let sectionIndex = candidate.sections.indexOf(updatedSection);
        // Pour que l'utilisateur ne puisse pas reset
        if (updatedSection && !updatedSection.startDate) {
            updatedSection.startDate = Date.now();
            candidate.sections[sectionIndex] = updatedSection;
        }
        if (updatedSection.step === 1) {
            candidate.testDate = {
                start: Date.now(),
                end: null
            };
        }
        updateCandidate(candidate)
            .then(() => {
                setLoading(false);
                dispatch(updateCandidateStore());
                if (updatedSection.isSkillTest) {
                    history.push(getSkillTestUrl(updatedSection.sectionName, 'question', 0));
                } else {
                    history.push(getPerceptionQuestionUrl(0));
                }
            })
    }

    return (
        candidate && section ?
            <Fragment>
                <Header step={section.step} sections={candidate.sections}/>
                {section.completed ?
                    <FinishedTest
                        section={section}
                        history={history}
                        candidate={candidate}
                    />
                    :
                    <RulesContainer
                        key={sectionId}
                        header={section.label}
                        content={section.rules}
                        method={initializeStartTest}
                        loading={loading}
                        isLogicTest={section.isSkillTest}
                    />
                }
            </Fragment>
            :
            loader
    )
}
