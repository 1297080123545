import React from 'react'
import {Link} from 'react-router-dom'
import './Polaroid.scss'
import Photo from '../Photo'
import {formatDateToDateTime, getTimeSinceDate} from 'utils/dateUtils';

export default function Polaroid(props) {

    const {fullName, keyPhoto, link, testDate, isEmpty} = props;
    const getTestDate = () => {
        if (testDate) {
            if (testDate.end) {
                return "Tests terminés le " + new Date(testDate.end).toLocaleDateString();
            } else if (testDate.start) {
                const diffTime = getTimeSinceDate(testDate.start);
                if (diffTime) {
                    return "Tests en cours depuis " + diffTime;
                } else {
                    return "Tests en cours depuis le " + formatDateToDateTime(testDate.start);
                }
            }
            return null;
        }
    }

    const getResultIcon = () => {
        if (testDate) {
            if (testDate.end) {
                return "check-icon";
            } else if (testDate.start) {
                return "waiting-icon"
            }
            return null;
        }
    }

    return (
        <Link className={`polaroid ${isEmpty ? 'empty' : ''}`} to={link}>
            <div className="d-flex">
                {!isEmpty && <Photo keyPhoto={keyPhoto}/>}
                <div className="user-infos">
                    <div className="d-flex align-items-center">
                    <div className="name">
                        {fullName}
                    </div>
                        <div className={`result-icon ${getResultIcon()}`}/>
                    </div>
                    <div className="test-result">
                        <span className="test-date">{getTestDate()}</span>
                    </div>
                </div>
            </div>
        </Link>
    )
}