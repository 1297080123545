import React, {Fragment, useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Button, Col, ProgressBar, Row, Spinner} from 'react-bootstrap'
import Header from 'components/header/Header'
import QuestionContainer from './questionContainer/QuestionContainer'
import NavPanel from './nav/NavPanel'
import OutOfTimeModal from 'components/modal/OutOfTimeModal'
import SeeRulesAgain from 'components/rules/SeeRulesAgain'
import FinishedTest from 'components/finishedTest/FinishedTest'
import Timer from './timer/Timer'
import {toast} from 'react-toastify'
import {getCandidateAndSection, updateCandidate as updateCandidateBDD} from 'service/candidate'
import {updateCandidate as updateCandidateStore} from 'store/actions/actionsCandidate'
import './LogicTestQuestion.scss'
import {CURENT_SECTION_ID} from 'data/constant'
import useLoader from 'components/loader/useLoader'
import {getSkillTestUrl} from 'enums/pageUrl';
import SubmitConfirmationModal from "../modal/SubmitConfirmationModal";

export default function LogicTestQuestion({history, match}) {

    const {params: {id}} = match;

    const dispatch = useDispatch();

    // Hook pour afficher un composant loader
    const [loader, showLoader, hideLoader] = useLoader();

    // Indicateur de chargement
    const [loading, setLoading] = useState(false);

    //Le candidat
    const [candidate, setCandidate] = useState(useSelector(state => state.candidate));
    const [section, setSection] = useState(null);

    const [timeLeft, setTimeLeft] = useState(null);

    //Etat initial du composant
    const [state, setState] = useState(
        {
            indexQuestion: parseInt(id),
            numberQuestions: section ? section.questions.length : null,
            currentQuestion: section ? section.questions[parseInt(id)] : null,
            outOfTime: false,
            submitTest: false,
            time: section ? section.time : null,
            completed: section ? section.completed : false,
        }
    );

    // Si candidate null on le récupère et on met à jour l'état du composant
    useEffect(() => {
        if (!candidate || !section) {
            showLoader();
            getCandidateAndSection(localStorage.getItem(CURENT_SECTION_ID))
                .then(result => {
                    setCandidate(result.candidate);
                    const currentSection = result.section;
                    setSection(currentSection);
                    setState({
                        ...state,
                        indexQuestion: parseInt(id),
                        currentQuestion: currentSection.questions[parseInt(id)],
                        numberQuestions: currentSection.questions.length,
                        timeTest: currentSection.time,
                        completed: currentSection.completed
                    });
                    setTimeLeft(currentSection.remainingTime);
                    hideLoader();
                })
        }
    }, []);

    //Lorsque l'id de la question change
    useEffect(() => {
        window.scrollTo(0, 0);
        if (candidate && section) {
            const question = section.questions[parseInt(id)];
            setState(
                {
                    ...state,
                    indexQuestion: parseInt(id),
                    currentQuestion: question
                }
            )
        }
    }, [id]);

    //Met à jour la question et la réponse sélectionné lorsque l'utilisateur choisit une réponse
    const handleAnswerChange = (event, index) => {
        setState(
            {
                ...state,
                currentQuestion: updateQuestion(event.target.value, index, event.target.type),
            }
        );
    };

    const handleRadioButtonUnselection = (index) => {
        let newQuestion = state.currentQuestion;
        newQuestion.choices[index].selected = false;
        setState(
            {
                ...state,
                currentQuestion: newQuestion
            }
        );
    }

    // Met à jour la question avec le bon nombre de point
    function updateQuestion(value, indexChoice, type) {
        let newQuestion = state.currentQuestion;
        if (newQuestion.choices) {
            if (type === "radio") {
                //Si c'est une question à réponse unique, on désélectionne toutes les réponses sauf celle reçue
                newQuestion.choices.forEach((choice, index) => {
                    choice.selected = index === indexChoice;
                });
            } else {
                //Sinon on inverse le statut de la réponse reçue
                newQuestion.choices[indexChoice].selected = !newQuestion.choices[indexChoice].selected;
            }
        } else {
            newQuestion.candidateAnswer = value;
        }
        return newQuestion;
    }

    //Lorsque l'utilisateur passe à la question suivante
    const submitAnswer = () => {
        section.questions[state.indexQuestion] = {
            ...state.currentQuestion,
            answered: Date.now()
        };

        setLoading(true);
        updateCandidateBDD(candidate)
            .then(() => {
                setLoading(false);
                dispatch(updateCandidateStore(candidate));
                if (state.indexQuestion === state.numberQuestions - 1) {
                    history.push(getSkillTestUrl(section.sectionName, 'validation', null));
                    setState({...state, submitTest: false});
                } else {
                    history.push(getSkillTestUrl(section.sectionName, 'question', state.indexQuestion + 1));
                }
            })
            .catch(error => {
                setLoading(false);
                toast.error(error.message, {position: toast.POSITION.TOP_RIGHT});
            })

    };

    const setTime = () => {
        if (timeLeft <= 0 && timeLeft !== null) {
            setState({
                ...state,
                outOfTime: true
            })
        }
        const timer = timeLeft > 0 && setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
        return () => clearTimeout(timer);
    }

    return (
        candidate && section ?
            <Fragment>
                <Header step={section.step} sections={candidate.sections}/>
                {state.completed ?
                    <FinishedTest
                        history={history}
                        section={section}
                        candidate={candidate}
                    />
                    :
                    (state.currentQuestion &&
                        <div>
                            <Row className="logic-test m-0">
                                <Col lg={{span: 2, offset: 2}} md={4}>
                                    <NavPanel
                                        history={history}
                                        index={state.indexQuestion + 1}
                                        sectionName={section.sectionName}
                                        questions={section.questions}
                                    />
                                </Col>
                                <Col lg={6} md={6}>
                                    <Row className="info-quiz">
                                        <Col>
                                            <Timer timeLeft={timeLeft} setTime={setTime}/>
                                        </Col>
                                        <Col md="auto">
                                            <span>Question {state.indexQuestion + 1}</span> sur {state.numberQuestions}
                                        </Col>
                                    </Row>
                                    <ProgressBar className="progressBar"
                                                 now={100 - (timeLeft / (state.timeTest * 60) * 100)}/>
                                    <QuestionContainer
                                        currentQuestion={state.currentQuestion}
                                        handleAnswerChange={handleAnswerChange}
                                        handleRadioButtonUnselection={handleRadioButtonUnselection}
                                    />
                                    <Row className="text-left col-12 m-2 align-items-center">
                                        <SeeRulesAgain header={section.label} content={section.rules}
                                                       isLogicTest={true}/>
                                    </Row>
                                    <div>
                                        {
                                            state.indexQuestion !== 0 ?
                                                <Button
                                                    className="m-2"
                                                    variant="outline-primary"
                                                    size="lg"
                                                    onClick={() => (history.push(getSkillTestUrl(section.sectionName, 'question', state.indexQuestion - 1)))}
                                                >
                                                    Question précédente
                                                </Button>
                                                : null
                                        }
                                        {
                                            state.indexQuestion < section.questions.length - 1 ?
                                                <Button
                                                    className="m-2"
                                                    variant="primary"
                                                    size="lg"
                                                    onClick={submitAnswer}
                                                >
                                                    Question suivante
                                                </Button>
                                                : null
                                        }
                                        {
                                            state.indexQuestion === section.questions.length - 1 ?
                                                <Button
                                                    className="m-2"
                                                    variant={"success"}
                                                    size="lg"
                                                    onClick={() => setState({...state, submitTest: true})}
                                                >
                                                    Finir le test
                                                    {
                                                        loading &&
                                                        <Spinner
                                                            size="sm"
                                                            as="span"
                                                            className="mr-2"
                                                            animation="border"
                                                            role="status"
                                                            aria-hidden="true"
                                                        />
                                                    }
                                                </Button>
                                                : null
                                        }
                                    </div>
                                </Col>
                            </Row>
                            <OutOfTimeModal
                                show={state.outOfTime}
                                sectionName={section.sectionName}
                                history={history}
                            />
                            <SubmitConfirmationModal
                                show={state.submitTest}
                                sectionName={section.sectionName}
                                history={history}
                                submit={submitAnswer}
                                cancel={() => setState({...state, submitTest: false})}
                                loading={loading}
                            />
                        </div>)
                }
            </Fragment>
            :
            loader
    )
}
