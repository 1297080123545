import React, {useRef} from 'react';
import JoditEditor from 'jodit-react';
import debounce from 'lodash.debounce';

export default function RichTextEditor(props) {

    const editor = useRef(null)

    const updateContentDebounced = debounce(props.updateContent, 500);

    const config = {
        placeholder: '',
        toolbarAdaptive: false,
        //Listes des boutons existants: https://xdsoft.net/jodit/doc/options/buttons/
        buttons: [
            'source', '|',
            'bold', 'strikethrough', 'underline', 'italic', '|',
            'superscript', 'subscript', '|',
            'ul', 'ol', '|',
            'outdent', 'indent', '|',
            'font', 'fontsize', 'brush', 'paragraph', 'symbol', '|',
            'table', 'link', '|',
            'align', 'undo', 'redo', 'selectall'
        ]
    }


    return (
        <JoditEditor
            ref={editor}
            value={props.content || ''}
            config={config}
            tabIndex={1}
            onBlur={newContent => updateContentDebounced(newContent)}
        />
    )
};