import React from 'react';
import {toast} from 'react-toastify';
import AutoPerceptionQuestionConfig from './AutoPerceptionQuestionConfig';
import LogicTestQuestionConfig from './LogicTestQuestionConfig';
import './QuestionConfigContainer.scss'
import {SECTION_NAME} from 'enums/sectionName';
import {checkQuestionValidity} from 'service/question';

export default function QuestionConfigContainer(props) {

    /**
     * @desc Récupère la question envoyée par le composant enfant et l'envoie au composant parent avec son index
     * @param question - question à ajouter/modifier
     */
    const addOrUpdateQuestion = (question) => {
        //Vérification de la validité de la question
        let errorList = checkQuestionValidity(question, props.sectionName);
        if(errorList.length === 0){
            props.addOrUpdateQuestion(question, props.index);
        } else {
            errorList.forEach(error => {
                toast.warn(error, {position: toast.POSITION.TOP_RIGHT});
            })
        }
    }

    /**
     * @desc Envoie au composant parent l'index de la question à supprimer
     */
    const deleteQuestion = () => {
        props.deleteQuestion(props.index);
    }

    switch (props.sectionName) {
        case SECTION_NAME.AUTO_PERCEPTION:
            return (
                <AutoPerceptionQuestionConfig
                    index={props.index}
                    question={props.question}
                    addOrUpdateQuestion={addOrUpdateQuestion}
                    deleteQuestion={deleteQuestion}
                />
            )
        //Case en cascade: dans tous ces cas le composant LogicTestQuestionConfig est renvoyé
        case SECTION_NAME.MATH:
        case SECTION_NAME.LOGIC_NUMBERS:
        case SECTION_NAME.LOGIC_LETTERS:
        case SECTION_NAME.LOGIC_SHAPES:
            return (
                <LogicTestQuestionConfig
                    index={props.index}
                    question={props.question}
                    sectionId={props.sectionId}
                    addOrUpdateQuestion={addOrUpdateQuestion}
                    deleteQuestion={deleteQuestion}
                />
            )
        default:
            return (
                <LogicTestQuestionConfig
                    index={props.index}
                    question={props.question}
                    sectionId={props.sectionId}
                    addOrUpdateQuestion={addOrUpdateQuestion}
                    deleteQuestion={deleteQuestion}
                />
            )
    }
}