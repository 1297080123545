import React from 'react'
import {Button, OverlayTrigger, Popover} from 'react-bootstrap'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";

export default function DeleteButton(props) {

    const handleDelete = () => {
        props.handleDelete();
        //Ferme le popover après suppression
        document.body.click();
    }

    const popover = (
        <Popover id="confirm-delete">
            <Popover.Title>Confirmer la suppression ?</Popover.Title>
            <Popover.Content className="d-flex justify-content-between">
                <Button size="sm" className="w-50 mr-2" variant="outline-danger" onClick={() => document.body.click()}>
                    Annuler
                </Button>
                <Button size="sm" className="w-50" variant="outline-success" onClick={handleDelete}>
                    Confirmer
                </Button>
            </Popover.Content>
        </Popover>
    );

    return (
        <OverlayTrigger trigger="click" placement={props.placement || "bottom"} overlay={popover} rootClose={true}>
            {props.buttonText ?
                <Button variant="outline-danger" size={props.size}>
                    {props.buttonText}
                </Button>
                :
                <FontAwesomeIcon className="removeAnswerButton" icon={faTrash} size={"sm"}/>
            }
        </OverlayTrigger>

    )
}