import React, {useState} from 'react'
import './Comment.scss'
import DeleteButton from "../../../../deleteButton/DeleteButton"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit} from "@fortawesome/free-solid-svg-icons";
import NewComment from "./NewComment";

export default function Comment(props) {
    const [editMode, setEditMode] = useState(false);

    const editComment = (updatedComment) => {
        props.addComment(updatedComment);
        setEditMode(false);
    }

    return (
        <div>
            {editMode ?
                <NewComment
                    candidate={props.candidate}
                    addComment={editComment}
                    comment={props.comment}
                />
                :
                <div className="font-italic text-left">
                    {props.comment.text || ''}
                </div>
            }
            <div className="d-flex align-items-center justify-content-start comment-data">
                <p className="author mr-1"> {props.comment.author || ''}</p>
                <p className="mr-2">{"- " + new Date(props.comment.date).toLocaleDateString()}</p>
                {!editMode &&
                    <>
                        <p>
                            <DeleteButton size="sm" icon={true} handleDelete={props.deleteComment}/>
                        </p>
                        <p className="edit-icon ml-2">
                            <FontAwesomeIcon size="sm" icon={faEdit} onClick={() => setEditMode(true)}/>
                        </p>
                    </>
                }
            </div>
        </div>
    )
}