export const AUTO_PERCEPTION_PROFILE = {
    ANIMATOR: {
        name: 'ANIMATEUR',
        code: 'ANI',
        strength: 'Sociable, coopératif, posé, sensible et conciliant. Il est attentif aux autres et à leurs besoins. Il est plein de tact. A l\'écoute, il évite les frictions et recherche les consensus. Il bâtit le relationnel dans l\'équipe.',
        weakness: 'Indécis dans les situations cruciales, il est facilement influençable. Il est souvent mal à l\'aise dans les situations de crise ou conflictuelles. Trop sensible à son environnement de travail.',
    },
    ANALYTIC: {
        name: 'CONTROLEUR - ANALYTIQUE',
        code: 'CA',
        strength: 'Modéré, stratégique. Il fait preuve de discernement et envisage toutes les options. Évalue avec précision et de manière objective si les idées sont réalistes et bénéfiques.',
        weakness: 'Perçu comme manquant de dynamisme, il a tendance à être trop rationnel, et parfois trop critique.',
    },
    CREATIVE: {
        name: 'CREATIF',
        code: 'CR',
        strength: 'Créatif, imaginatif, peu orthodoxe et surtout anticonformiste, il propose de nouvelles idées et des solutions créatives. Il résout des problèmes complexes.',
        weakness: 'Il a tendance à négliger les détails pratiques. Trop absorbé pour se soucier de communiquer efficacement. Réinvente la roue.',
    },
    COORDINATOR: {
        name: 'COORDINATEUR',
        code: 'COO',
        strength: 'Mature, confiant, attentif aux autres et diplomate. Il clarifie les objectifs et fait progresser les prises de décision. Il s\'assure que les efforts et les qualités des coéquipiers sont utilisés au mieux. Il organise les tâches entre les personnes de son équipe vers le but donné.',
        weakness: 'Il peut parfois être perçu comme manipulateur. Il a tendance à déléguer le travail personnel qu\'il n\'aime pas faire.',
    },
    DYNAMIC: {
        name: 'DYNAMIQUE',
        code: 'DY',
        strength: 'Il aime les défis. Dynamique et fonceur, il travaille bien sous pression. Il a le courage et la capacité de surmonter les obstacles. Il pousse les autres à l\'action et aide l\'équipe à recentrer son travail. Orienté but d’abord.',
        weakness: 'Il peut provoquer les autres et être parfois blessant mais sans intention malveillante. Sans défi, il s’ennuie.',
    },
    EXPERT: {
        name: 'EXPERT',
        code: 'EXP',
        strength: 'Déterminé, autonome, concentré vers un objectif. Il possède une source de connaissances et de compétences techniques peu communes et utiles à l\'équipe.',
        weakness: 'Il possède un champ de compétence généralement étroit. Il capitalise sur les aspects techniques. Il a tendance à négliger la vision globale.',
    },
    ORGANIZER: {
        name: 'ORGANISATEUR',
        code: 'ORG',
        strength: 'Mature, confiant, attentif aux autres et diplomate. Il clarifie les objectifs et fait progresser les prises de décision. Il s\'assure que les efforts et les qualités des coéquipiers sont utilisés au mieux. Il organise les tâches entre les personnes de son équipe vers le but donné.',
        weakness: 'Il peut parfois être perçu comme manipulateur. Il a tendance à déléguer le travail personnel qu\'il n\'aime pas faire.',
    },
    PERFECTIONIST: {
        name: 'PERFECTIONNISTE',
        code: 'PF',
        strength: 'Il s\'assure que le travail est bien fini. Consciencieux, à la recherche des erreurs et omissions. Il a le souci de la perfection. Il fait en sorte que le programme et les délais soient respectés.',
        weakness: 'Enclin à s\'inquiéter pour chaque détail, il a des difficultés à déléguer. Il a tendance à déléguer le travail personnel qu\'il n\'aime pas faire.',
    },
    PROMOTER: {
        name: 'PROMOTEUR',
        code: 'PRO',
        strength: 'Extraverti, enthousiaste et communicatif. Il explore les opportunités et développe les contacts à l\'intérieur et à l\'extérieur de l\'équipe et de l\'entreprise. Il tire des profits des idées, des informations et des développements du monde extérieur.',
        weakness: 'Trop optimiste. Il manque parfois de réalisme et a tendance à se désintéresser des projets lorsque l’enthousiasme initial est passé.',
    }
}