import React from 'react';
import {Link} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {getSectionPageUrl} from 'enums/pageUrl';

export default function SectionIconButton(props) {

    return (
        <Link to={getSectionPageUrl(props.sectionName, props.sectionId)} className="d-flex align-items-center">
            <FontAwesomeIcon icon={props.icon}/>
            <div className="pl-2 section-title">{props.text}</div>
        </Link>
    )

}