import React from 'react'
import './NavPanel.scss'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCheck, faClock, faQuestion} from '@fortawesome/free-solid-svg-icons'
import {getSkillTestUrl} from 'enums/pageUrl';

// Composant pour accéder à la question correspondant au numéro
const NavItem = (props) => {

    const {history, index, num, question, sectionName} = props;

    // Affiche le bon logo en fonction du status de la question
    function statusIndicator() {
        // La question est en cours de traitement
        if (index === num) {
            return (
                <FontAwesomeIcon icon={faClock}/>
            )
        }
        // La question a été répondue
        else if (question.answered) {
            // Vérifie si la réponse est "Je ne sais pas"
            let isReallyAnswered = false;
            // Si la question est à choix multiple
            if (question.choices) {
                question.choices.forEach(choice => {
                    // Si un choix a été sélectionné
                    if (choice.selected) {
                        return isReallyAnswered = true; // Alors la question a bien été répondue
                    }
                })
                return (
                    isReallyAnswered ?
                        <FontAwesomeIcon className="text-info" size="sm" icon={faCheck}/>
                        :
                        <FontAwesomeIcon className="text-secondary" size="sm" icon={faQuestion}/>
                )
            }
            // Si la question n'est pas un choix multiple
            else {
                return (
                    question.candidateAnswer ?
                        <FontAwesomeIcon className="text-info" size="sm" icon={faCheck}/>
                        :
                        <FontAwesomeIcon className="text-secondary" size="sm" icon={faQuestion}/>
                )

            }
        }
        // La question n'a pas été répondue
        else {
            return (
                <FontAwesomeIcon className="text-secondary" size="sm" icon={faQuestion}/>
            )
        }
    }

    return (
        <button
            onClick={() => (history.push(getSkillTestUrl(sectionName, 'question', num - 1)))}
            className={index === num ? 'nav-item active' : 'nav-item'}
        >
            {num} - {statusIndicator()}
        </button>
    )
};

// Composant pour accéder au menu de naviguation des questions
export default function NavPanel(props) {
    const {history, index, questions, sectionName} = props;

    let navItems = [];
    for (let i = 1; i <= questions.length; i++) {
        navItems.push(
            <NavItem
                history={history}
                key={i}
                num={i}
                index={index}
                question={questions[i - 1]}
                sectionName={sectionName}
            />
        )
    }

    return (
        <div className="nav-panel">
            {navItems} {/*On affiche tous les navItems*/}
        </div>
    )
}