import React, {useEffect, useState} from 'react'
import Document from './Document'
import {TYPE_DOCUMENT} from 'data/constant'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faExclamationTriangle} from '@fortawesome/free-solid-svg-icons';

export default function DocumentsCandidate(props) {
    const {candidate} = props;

    const [files, setFiles] = useState({});

    useEffect(() => {
        getFile();
    }, []);

    function getFile() {
        let tmp = {};
        if (candidate.photo) {
            tmp.photo = candidate.photo;
        }
        if (candidate.cv) {
            tmp.cv = candidate.cv;
        }
        setFiles(tmp);
    }

    return (
        <div className="d-flex flex-column">
            {(files.photo) ?
                Object.entries(files).map(([key, value]) => {
                    return (
                        <Document
                            key={key}
                            filename={key === 'photo' ? 'Photo' : 'CV'}
                            type={key === 'photo' ? TYPE_DOCUMENT.IMAGE : TYPE_DOCUMENT.PDF}
                            keyFile={value}
                        />
                    )
                })
                :
                <div className="p-4 d-flex flex-row align-items-center">
                    <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2"/>
                    <span>Aucun document pour le moment</span>
                </div>
            }

        </div>
    )
}