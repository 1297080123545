import {del, get, post} from 'service/utils/httpRequest';
import {TOKEN} from 'data/constant';
import {SECTION_NAME} from 'enums/sectionName';

import config from 'config';

const API_URL = config.apiGateway.URL;

function defaultHeaders(tokenType) {
    return {Authorization: `Bearer ${sessionStorage.getItem(TOKEN)}`};
}

export async function createSection(sectionData) {
    return post(API_URL + '/sections', defaultHeaders(), buildCreateSectionRequest(sectionData));
}

export async function updateSection(sectionId, sectionData) {
    return post(API_URL + `/sections/${sectionId}`, defaultHeaders(), sectionData);
}

export async function deleteSection(sectionId) {
    return del(API_URL + `/sections/${sectionId}`, defaultHeaders());
}

export async function getSectionById(sectionId) {
    return get(API_URL + `/sections/${sectionId}`, defaultHeaders());
}

export async function getSections(sectionName) {
    if (sectionName) {
        return get(API_URL + '/sections', defaultHeaders(), {sectionName: sectionName});
    } else {
        return get(API_URL + '/sections', defaultHeaders());
    }
}

//Retourne une url signé permettant d'upload un fichier dans le bucket d'image
export async function requestUploadImage(sectionId, file) {
    return post(API_URL + `/sections/${sectionId}/request-upload-url`, defaultHeaders(), file);
}

export async function getUrlImage(keyImage) {
    return get(API_URL + `/sections/image`, defaultHeaders(), {keyImage: keyImage});
}

export async function deleteQuestionImage(keyImage) {
    return del(API_URL + `/sections/image`, defaultHeaders(), {keyImage: keyImage});
}

function buildCreateSectionRequest(sectionData) {
    const {sectionName, label, icon, questions, rules, time} = sectionData;
    return {
        sectionName,
        label,
        icon,
        questions,
        rules,
        isSkillTest: true,
        time
    }
}

/**
 * @desc Vérifie la validité d'une question en fonction de la section et du type de question
 * @param question
 * @param sectionName
 * @return Liste d'erreur
 */
export function checkQuestionValidity(question, sectionName) {
    if (sectionName === SECTION_NAME.AUTO_PERCEPTION) {
        return checkAutoPerceptionQuestionValidity(question);
    } else {
        if (question.choices) {
            return checkMCQuestionValidity(question);
        } else {
            return checkOpenQuestionValidity(question);
        }
    }
}

/**
 * @desc Vérifie la validité d'une question d'auto-perception
 * @param question
 * @return Liste d'erreur
 */
function checkAutoPerceptionQuestionValidity(question) {
    let errorMessage = [];
    if (!question.header) {
        errorMessage.push('Intitulé de la question manquant');
    }
    if (!question.choices) {
        errorMessage.push('Il n\'y a aucune réponse dans la question');
    } else if (question.choices.length < 2) {
        errorMessage.push('La question doit avoir un minimum de 2 réponses');
    } else {
        //Récupération de tous les profils
        let profiles = question.choices.map((choice, index) => {
            //On vérifie qu'il y a un profil pour chaque réponse
            if (!choice.character) {
                errorMessage.push(`Profil manquant pour la réponse N°${index + 1}`);
            } else {
                return choice.character;
            }
        });
        //Récupération de tous intitulé de réponse
        let labels = question.choices.map((choice, index) => {
            //On vérifie qu'il y a un intitulé pour chaque réponse
            if (!choice.label || choice.label === '') {
                errorMessage.push(`Texte manquant pour la réponse N°${index + 1}`);
            } else {
                return choice.label;
            }
        });
        //On vérifie qu'il n'y a pas de doublon dans les profils et dans les intitulés de réponse
        const duplicatesProfiles = getDuplicatesInArray(profiles);
        const duplicatesLabels = getDuplicatesInArray(labels);
        if (duplicatesProfiles) {
            errorMessage.push(`Profil(s) ${duplicatesProfiles.toString()} en double dans les réponses`);
        }
        if (duplicatesLabels) {
            errorMessage.push('Un ou plusieurs intitulés de réponses sont identiques');
        }
    }
    return errorMessage;
}

/**
 * @desc Vérifie la validité d'une question de type QCM
 * @param question
 * @return Liste d'erreur
 */
function checkMCQuestionValidity(question) {
    let errorMessage = [];
    if (!question.header && !question.image) {
        errorMessage.push('La question doit contenir un intitulé ou une image');
    }
    if (question.choices.length < 2) {
        errorMessage.push('La question doit avoir un minimum de 2 réponses');
    } else {
        //Récupération de tous intitulé de réponse
        let labels = question.choices.map((choice, index) => {
            //On vérifie qu'il y a un intitulé pour chaque réponse
            if (!choice.label || choice.label === '') {
                errorMessage.push(`Texte manquant pour la réponse N°${index + 1}`);
            } else {
                return choice.label;
            }
        });
        //On vérifie qu'il n'y a pas de doublon dans les intitulés de réponse
        const duplicatesLabels = getDuplicatesInArray(labels);
        if (duplicatesLabels) {
            errorMessage.push('Un ou plusieurs intitulés de réponses sont identiques');
        }

        let correctAnswer = question.choices.map(choice => choice.correct);
        //On vérifie qu'il y a au moins une bonne réponse
        if (!correctAnswer.includes(true)) {
            errorMessage.push('La question doit contenir au moins une bonne réponse');
        }
    }
    return errorMessage;
}

/**
 * @desc Vérifie la validité d'une question ouverte
 * @param question
 * @return Liste d'erreur
 */
function checkOpenQuestionValidity(question) {
    let errorMessage = [];
    if (!question.header && !question.image) {
        errorMessage.push('La question doit contenir un intitulé ou une image');
    }
    if (!question.answer.requiresCorrection && !question.answer.value) {
        errorMessage.push('Les questions ouvertes ne nécessitant pas de correcteur doivent contenir une réponse');
    }
    return errorMessage;
}

/**
 * @desc Récupère les doublons dans un tableau
 * @param array
 * @return null si aucun doublon, la liste de doublons sinon
 */
export function getDuplicatesInArray(array) {
    let duplicates = array.filter((item, index) => array.indexOf(item) !== index);
    const uniqueDuplicates = Array.from(new Set(duplicates));
    return uniqueDuplicates.length > 0 ? uniqueDuplicates : null;
}
