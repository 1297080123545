import React, {Fragment, useEffect, useState} from 'react'
import Header from 'components/header/Header'
import ValidationCard from 'components/validation/ValidationCard'
import {useDispatch, useSelector} from 'react-redux'
import {calculateScore, createPdf, updateCandidate, getCandidateAndSection} from 'service/candidate'
import {updateCandidate as updateCandidateStore} from 'store/actions/actionsCandidate'
import {CURENT_SECTION_ID} from 'data/constant'
import EndModal from 'components/modal/EndModal'
import {toast} from 'react-toastify'
import {getRulesPageUrl} from 'enums/pageUrl';
import useLoader from 'components/loader/useLoader';

export default function LogicTestValidation({history}) {

    const dispatch = useDispatch();
    const [candidate, setCandidate] = useState(useSelector(state => state.candidate));
    const [section, setSection] = useState(null);
    const [nextSection, setNextSection] = useState(null);

    // Hook pour afficher un composant loader
    const [loader, showLoader, hideLoader] = useLoader();

    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!candidate || !section) {
            showLoader();
            getCandidateAndSection(localStorage.getItem(CURENT_SECTION_ID))
                .then((result) => {
                    setCandidate(result.candidate);
                    setSection(result.section);
                    setNextSection(result.candidate.sections.find(section => section.step === result.section.step + 1));
                    hideLoader();
                })
        }
    }, [candidate]);

    const updateTest = () => {
        setLoading(true);
        let newCandidate = candidate;
        let newSection = section;
        let sectionIndex = candidate.sections.indexOf(section);
        // Si le test est déjà été validé
        if (newSection.completed) {
            if (nextSection) {
                setLoading(false);
                history.push(getRulesPageUrl(nextSection.sectionName, nextSection.sectionId));
            } else {
                setLoading(false);
                setShowModal(true);
            }
        }
        // Si le test n'as pas encore été validé
        else {
            newSection.completed = true;
            newCandidate.sections[sectionIndex] = newSection;
            updateCandidate(newCandidate)
                .then(() => {
                    dispatch(updateCandidateStore(newCandidate));
                    if (nextSection) {
                        setLoading(false);
                        history.push(getRulesPageUrl(nextSection.sectionName, nextSection.sectionId));
                    } else {
                        //calc score
                        calculateScore(newCandidate['candidateId'])
                            .then((result) => {
                                dispatch(updateCandidateStore(newCandidate));
                                setCandidate(result.data);
                                createPdf(result.data['candidateId']);
                            })
                            .then(() => {
                                setLoading(false);
                                setShowModal(true);
                            })
                            .catch(() => {
                                toast.error(
                                    'Erreur lors du calcul de vos résultats, réessayez ou contactez Coreoz',
                                    {position: toast.POSITION.TOP_RIGHT}
                                )
                            })
                    }
                })
                .catch(() => {
                    toast.error(
                        'Erreur lors de la validation du test',
                        {position: toast.POSITION.TOP_RIGHT}
                    )
                })
        }
    };

    return (
        candidate && section ?
            <Fragment>
                <Header step={section.step} sections={candidate.sections}/>
                <ValidationCard sectionLabel={section.label} method={updateTest} loading={loading}/>
                <EndModal showModal={showModal}
                          candidateScore={candidate['globalScore']}
                          numbQuestions={candidate['globalNumbQuestions']}
                />
            </Fragment>
            : loader
    )
}