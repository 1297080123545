import React, {useEffect, useState} from 'react'
import {Form} from 'react-bootstrap'
import './SearchCandidate.scss'
import makeCancellablePromise from 'make-cancellable-promise';
import {getCandidates} from 'service/admin';
import {toast} from 'react-toastify';

export default function SearchCandidate(props) {

    const [fields, setFields] = useState({
        order: 'alpha',
        name: '',
        school: '',
        status: {
            trainee: false,
            intern: false,
            extern: false
        },
        promotionYearList: []
    });

    const handleChange = (event) => {
        props.setPage(1)
        //Si c'est une date on ajoute l'année
        if (event._d) {
            const date = new Date(event._d);
            const year = date.getFullYear();
            if (fields.promotionYearList.indexOf(year) === -1) {
                setFields({
                        ...fields,
                        promotionYearList: fields.promotionYearList.concat(year)
                    }
                )
            }
        }
        //Si c'est un status on l'ajoute
        else if (event.target.id === 'status') {
            let statusList = [];
            if (event.target.value !== 'Type de contrat') {
                statusList[event.target.value] = true;
            }
            setFields({
                ...fields,
                status: statusList
            });
        } else if (event.target.id === 'agency') {
            if (event.target.value !== 'Agence') {
                setFields({
                    ...fields,
                    agency: event.target.value
                });
            } else {
                setFields({
                    ...fields,
                    agency: undefined
                });
            }
        } else if (event.target.name === 'order') {
            setFields({
                ...fields,
                order: event.target.value,
            });
        } else {
            //Sinon c'est un champ classique
            setFields({
                ...fields,
                [event.target.id]: event.target.value,
            });
        }
    };

    useEffect(() => {
        const {promise, cancel} = makeCancellablePromise(getCandidates(fields, props.page));

        props.setIsRequestPending(true)
        promise
            .then(result => {
                const chunks = [];
                const chunkSize = 4;
                for (let i = 0; i < result.data.candidateList.length; i += chunkSize) {
                    const chunk = result.data.candidateList.slice(i, i + chunkSize)
                    if (chunk.length < chunkSize) {
                        const currentLength = chunk.length
                        for (let j = 0; j < chunkSize - currentLength; j++) {
                            chunk.push({isEmpty: true})
                        }
                    }
                    chunks.push(chunk)
                }
                props.setCandidates(chunks)
                props.setTotal(result.data.total)
                props.setIsRequestPending(false)
            })
            .catch(error => {
                toast.error(error.message, {position: toast.POSITION.TOP_RIGHT})
                props.setIsRequestPending(false)
            });

        return () => {
            cancel();
        };
    }, [fields, props.page]);

    return (
        <Form className="search d-flex">
            <Form.Group className="search-bar">
                <Form.Control
                    type="text"
                    placeholder="Nom/Prénom"
                    id="name"
                    onChange={handleChange}
                />
            </Form.Group>
            <Form.Group className="search-select mr-4">
                <Form.Control
                    as="select"
                    id="status"
                    custom
                    onChange={handleChange}
                >
                    <option>Type de contrat</option>
                    <option value="trainee">Stagiaire</option>
                    <option value="extern">Prestataire</option>
                    <option value="intern">CDI</option>
                </Form.Control>
            </Form.Group>
            <Form.Group className="search-select">
                <Form.Control
                    as="select"
                    id="agency"
                    custom
                    onChange={handleChange}
                >
                    <option>Agence</option>
                    <option value="Paris">Paris</option>
                    <option value="Lyon">Lyon</option>
                </Form.Control>
            </Form.Group>
        </Form>
    )
}
