import React, {Fragment, useEffect, useState} from 'react'
import {Form, FormControl, FormGroup, Row} from 'react-bootstrap'
import {faCheckCircle, faCheckSquare} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {getUrlImage} from 'service/question';
import {toast} from 'react-toastify';
import Image from 'react-bootstrap/Image';

export default function QuestionContainer(props) {

    const {currentQuestion, handleAnswerChange, handleRadioButtonUnselection} = props;

    const [image, setImage] = useState(null);

    useEffect(() => {
        //Si la question contient une image mais qu'elle est absente, on la récupère
        if (currentQuestion.image) {
            getImage(currentQuestion.image);
        } else {
            setImage(null);
        }
    }, [currentQuestion]);

    const getImage = (imageKey) => {
        if (imageKey) {
            getUrlImage(imageKey)
                .then(result => {
                    setImage(result.data);
                })
                .catch(error => {
                    toast.error(error.message, {position: toast.POSITION.TOP_RIGHT})
                })
        }
    };

    const getIsMultipleAnswerQuestion = () => {
        if (currentQuestion.choices) {
            let correctAnswerNumber = 0;
            // On cherche le nombre de bonnes réponses
            currentQuestion.choices.forEach(choice => {
                if (choice['correct']) {
                    correctAnswerNumber++;
                }
            });
            return correctAnswerNumber > 1;
        } else {
            return false;
        }
    }

    const isMultipleAnswerQuestion = getIsMultipleAnswerQuestion();

    return (
        <div className="paper-card text-left">
            <div className="header mb-3">
                {
                    isMultipleAnswerQuestion ?
                        <FontAwesomeIcon className="text-secondary mr-2 ml-1" size="sm" icon={faCheckSquare}/>
                        :
                        currentQuestion.choices &&
                        <FontAwesomeIcon className="text-secondary mr-2 ml-1" size="sm" icon={faCheckCircle}/>
                }
                {
                    currentQuestion.header && <span>{currentQuestion.header}</span>
                }

            </div>
            {
                image &&
                <Row className="justify-content-center mb-3">
                    <Image src={image} alt="Enoncé de la question" rounded fluid/>
                </Row>
            }
            <div>
                {currentQuestion.choices ?
                    <Fragment>
                        {currentQuestion.choices.map((choice, index) => (
                            <div key={index}
                                 className={'container-choice ' + (isMultipleAnswerQuestion ? 'type-checkbox' : 'type-radio')}>
                                <Form.Label>
                                    {choice.label}
                                    <input
                                        type={isMultipleAnswerQuestion ? "checkbox" : "radio"}
                                        value={index}
                                        checked={!!choice.selected}
                                        onClick={() => {
                                            if (!isMultipleAnswerQuestion && choice.selected){
                                                handleRadioButtonUnselection(index);
                                            }
                                        }}
                                        onChange={(event) => handleAnswerChange(event, index)}
                                    />
                                    <span className="check-mark"/>
                                </Form.Label>
                            </div>
                        ))}
                    </Fragment>

                    :
                    <FormGroup>
                        <FormControl
                            as="textarea" 
                            rows={10}
                            placeholder="Votre réponse"
                            value={currentQuestion.candidateAnswer || ''}
                            id="answer"
                            onChange={(event) => handleAnswerChange(event)}
                        />
                    </FormGroup>
                }
            </div>
        </div>
    )
};
