import React, {useEffect, useState} from 'react'
import {Button, Col, Form, Modal, Row} from 'react-bootstrap';
import {useFormFields} from 'utils/hooksForms';
import {getSections} from 'service/question';
import {toast} from 'react-toastify';
import ListGroup from 'react-bootstrap/ListGroup';
import './CandidateForm.scss';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";

export default function ModalTestChoice(props) {

    const [sections, setSections] = useState([]);
    const [selectedSectionIdList, setSelectedSectionIdList] = useState([]);
    const [fields, setFields] = useFormFields({
        sections: []
    });

    useEffect(() => {
        getSections()
            .then(response => {
                setSections(response.data['Items']);
            })
            .catch(error => {
                toast.error(error.message, {position: toast.POSITION.TOP_RIGHT});
            })
    }, []);

    /**
     * @desc Ajoute/Supprime une section dans la liste de sections du candidat
     * @param id Identifiant de la section
     */
    const toggleSection = id => {
        const section = sections.find(section => section.sectionId === id);
        //Si la section est déjà dans la liste, on la supprime
        if (selectedSectionIdList.includes(id)) {
            let newSelectedSectionIdList = [...selectedSectionIdList];
            newSelectedSectionIdList.splice(newSelectedSectionIdList.indexOf(id), 1);
            setSelectedSectionIdList(newSelectedSectionIdList);
        } else {
            //Sinon on l'ajoute
            setSelectedSectionIdList([...selectedSectionIdList, id]);
        }
    }

    const handleSubmit = event => {
        event.preventDefault();
        fields.sections = [...selectedSectionIdList];

        if (fields.sections.length === 0) {
            toast.warn('Aucun test n\'est sélectionné pour le candidat', {position: toast.POSITION.TOP_RIGHT});
        } else {
            props.sendFormData(fields);
        }
    };

    const sectionSelectList = () => {
        if (Array.isArray(sections) && sections.length > 0) {
            return sections.map((section, index) =>
                <Form.Check
                    custom
                    inline
                    role="button"
                    className="text-left cursor-pointer"
                    name="section"
                    label={section.label}
                    value={section.sectionId}
                    id={section.sectionId}
                    key={index}
                    checked={selectedSectionIdList.includes(section.sectionId)}
                    onChange={() => toggleSection(section.sectionId)}
                />
            );
        } else {
            return null;
        }
    };

    return (
        <Modal
            show={true}
            size="lg"
            centered
            backdrop="static"
        >
            <div className="d-flex flex-row justify-content-end">
                <FontAwesomeIcon role="button" className="mt-4 mr-4" icon={faTimes} size="lg" onClick={props.closeModal}/>
            </div>
            <div className="mx-4 mb-4 text-center">
                <h3 className="text-center">Choix des<span className="accentuated-title"> tests</span></h3>
                <Form className="candidate-form" onSubmit={handleSubmit}>
                    <Row>
                        <Col>
                            <ListGroup>
                                {
                                    sectionSelectList()
                                }
                            </ListGroup>
                        </Col>
                    </Row>
                    <Button
                        variant="primary"
                        className="mt-4"
                        type="submit"
                    >
                        Enregistrer et envoyer le mail
                    </Button>
                </Form>
            </div>
        </Modal>
    )
}