import React, {useState} from 'react'
import {TYPE_DOCUMENT} from 'data/constant';
import Document from '../documents/Document';
import {formatDateToDateTime} from 'utils/dateUtils';
import Table from "react-bootstrap/Table";
import {AUTO_PERCEPTION_PROFILE} from "../../../../../enums/autoPerceptionProfile";
import {Button, Spinner} from "react-bootstrap";
import {generatePDF} from "../../../../../service/candidate";
import ModalTestChoice from "../../ModalTestChoice";
import {sendNewTest} from "../../../../../service/admin";
import {toast} from "react-toastify";

export default function ResultCandidate(props) {

    const resultPdfList = props.candidate.resultPdfList || [];
    const autoPerceptionScore = props.candidate.sections.find((section) => !section.isSkillTest)?.score;
    const [loading, setLoading] = useState(false);
    const [showModalTestChoice, setShowModalTestChoice] = useState(false);

    let percentageScore = props.candidate.globalScore && props.candidate.globalNumbQuestions ?
        Math.round((props.candidate.globalScore / props.candidate.globalNumbQuestions) * 100) : null;

    const resultFileList = () => {
        return resultPdfList.map((file, index) => {
            const section = props.candidate.sections.find(section => section.sectionId === file.sectionId);
            const fileName = "Rapport " + (section ? section.label : "des tests");
            return (
                <div key={index} className="document-container">
                    <Document
                        filename={fileName}
                        type={TYPE_DOCUMENT.PDF}
                        keyFile={file.key}
                    />
                </div>
            )
        });
    }

    const handleGeneratePDF = () => {
        setLoading(true)
        generatePDF(props.candidate.candidateId).then(() => {
            props.reloadData()
        }).finally(() => {
            setLoading(false)
        })
    }

    const sendTestChoice = (data) => {
        setShowModalTestChoice(false);
        sendNewTest(props.candidate.candidateId, data.sections).then(() => {
            toast.success('Tests envoyés au candidat', {position: toast.POSITION.TOP_RIGHT});
            props.reloadData()
        });
    }

    return (
        <div className="text-left">
            <Table className="profile-table">
                <tbody>
                <tr>
                    <th className="medium-col text-left">
                        <span>Tests envoyés</span>
                    </th>
                    <th className="text-left">
                        <span className="text-left">{props.candidate.sections.map((section, index) => section.label).join(', ')}</span>
                    </th>
                </tr>
                <tr>
                    <th className="medium-col text-left">
                        <span>Date de début</span>
                    </th>
                    <th className="text-left">
                        <span>{formatDateToDateTime(props.candidate.testDate?.start)}</span>
                    </th>
                </tr>
                <tr>
                    <th className="medium-col text-left">
                        <span>Date de fin</span>
                    </th>
                    <th className="text-left">
                        {props.candidate.testDate?.end && <span>{formatDateToDateTime(props.candidate.testDate?.end)}</span>}
                    </th>
                </tr>
                <tr>
                    <th className="medium-col text-left">
                        <span>Score global</span>
                    </th>
                    <th className="text-left">
                        {props.candidate.globalScore && <span>{props.candidate.globalScore}/{props.candidate.globalNumbQuestions}, soit {percentageScore}% de réussite</span>}
                    </th>
                </tr>
                </tbody>
            </Table>
            <Button className="ml-2 mb-4" variant="outline-primary" size="sm" onClick={() => setShowModalTestChoice(true)}>
                Envoyer d'autres tests
            </Button>
            {autoPerceptionScore &&
                <Table className="auto-perception-table">
                    <thead>
                    <tr>
                        {Object.keys(autoPerceptionScore).map(key => (
                            <th key={key}>{AUTO_PERCEPTION_PROFILE[key].code}</th>
                        ))}
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        {Object.keys(autoPerceptionScore).map(key => (
                            <td key={key}>{autoPerceptionScore[key]}</td>
                        ))}
                    </tr>
                    </tbody>
                </Table>
            }


            <Table className="profile-table">
                <tbody>
                {props.candidate.sections.filter(section => section.isSkillTest && section.score !== undefined).map(section =>
                    (
                        <tr key={section.label}>
                            <th className="medium-col text-left">
                                <span>{section.label}</span>
                            </th>
                            <th className="text-left">
                                <span className="text-left">{`${section.numbAnswer}/${section.numbQuestion} répondues avec ${section.numbIncorrect} faute(s)`}</span>
                            </th>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <div className="mt-3 document-list">
                {
                    resultFileList()
                }
               {resultPdfList.length !== props.candidate.sections.filter(section => section.score !== undefined).length &&
                    <Button
                        variant="outline-primary"
                        size="md"
                        type="submit"
                        onClick={handleGeneratePDF}
                    >
                        {loading &&
                            <Spinner
                                size="sm"
                                as="span"
                                className="mr-2"
                                animation="border"
                                role="status"
                                aria-hidden="true"
                            />
                        }
                        Regénérer les PDF
                    </Button>
                }
            </div>
            {showModalTestChoice && <ModalTestChoice sendFormData={sendTestChoice} closeModal={() => setShowModalTestChoice(false)}/>}
        </div>
    )
}
