import React from 'react'
import {Button, Modal, Spinner} from 'react-bootstrap'
import './OutOfTime.scss'

export default function SubmitConfirmationModal(props) {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            keyboard={false}
            backdrop="static"
        >
            <Modal.Body className="modal-body">
                <Modal.Title className="modal-custom-title">Validation du test</Modal.Title>
                <p className="modal-text">
                    Attention, en cliquant sur Valider, vous ne pourrez plus revenir en arrière pour modifier ou compléter les questions du test.
                    Êtes-vous sûr(e) de vouloir continuer ?
                </p>
                <Button variant="outline-primary" size="lg" onClick={props.cancel}>Annuler</Button>
                <Button className="ml-4" size="lg" onClick={props.submit}>
                    Valider
                    {
                        props.loading &&
                        <Spinner
                            size="sm"
                            as="span"
                            className="mr-2"
                            animation="border"
                            role="status"
                            aria-hidden="true"
                        />
                    }
                </Button>
            </Modal.Body>
        </Modal>
    );
}