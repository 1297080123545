/**
 * @desc Formate une durée de minute en une string xxhxxm
 * @param minutes
 */
export function getFormattedDuration(minutes) {
    if (minutes) {
        let formattedDuration = new Date(1000 * parseInt(minutes) * 60).toISOString().substr(11, 6);
        formattedDuration = formattedDuration.replace(':', 'h').replace(':', 'm');
        return formattedDuration;
    } else {
        return null;
    }
}