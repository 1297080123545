import React, { useEffect } from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faClock} from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'

const Timer = (props) => {

    const {timeLeft, setTime} = props;

    useEffect(() => {
        setTime()
    }, [timeLeft]);


    return (
        <div>
            <FontAwesomeIcon icon={faClock} className="mr-2"/>
            
            {moment.utc(moment.duration(timeLeft, "seconds").asMilliseconds()).format("mm:ss")}
        </div>
    )
}

export default Timer;