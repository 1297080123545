import React, {Fragment, useState} from 'react'
import {Spinner} from 'react-bootstrap'
import NavigationBar from 'components/admin/navbar/NavigationBar'
import {createCandidate} from 'service/admin'
import {toast} from 'react-toastify'
import {PAGES} from 'enums/pageUrl'
import CandidateForm from '../CandidateForm';

export default function NewCandidate(props) {

    const [loading, setLoading] = useState(false);
    const sendFormData = fields => {
        setLoading(true);
        createCandidate(fields)
            .then( () => {
                setLoading(false);
                toast.success('Candidat créé, l\'email a été envoyé', {position: toast.POSITION.TOP_RIGHT});
                props.history.push(PAGES.CANDIDATES_ADMIN);
            })
            .catch( () => {
                setLoading(false);
                toast.error('Impossible de créer le candidat', {position: toast.POSITION.TOP_RIGHT});
            })
    };

    return (
        <Fragment>
            <NavigationBar/>
            <CandidateForm
                sendFormData={sendFormData}
            />
            {   loading &&
                <Spinner
                    size="sm"
                    as="span"
                    className="mr-2"
                    animation="border"
                    role="status"
                    aria-hidden="true"
                />
            }
        </Fragment>
    )
}