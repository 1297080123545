import React from 'react'
import {Modal} from 'react-bootstrap'
import './RulesModal.scss'
import PictoSignification from '../rules/PictoExplication';

export default function RulesModal(props) {
    return (
        <Modal
            show={props.show}
            onHide={props.onHide}
            size="lg"
            aria-labelledby="rules-modal"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title className="modal-custom-title">
                    {props.header}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-left m-2">
                <div className="rules" dangerouslySetInnerHTML={{__html: props.content}}/>
                <div>
                    {
                        props.isLogicTest ?
                            <PictoSignification/>
                            : null
                    }
                </div>
            </Modal.Body>
        </Modal>
    );
}