import React, {Fragment, useState} from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faInfoCircle} from '@fortawesome/free-solid-svg-icons'
import RulesModal from 'components/modal/RulesModal'
import './SeeRulesAgain.scss'

function SeeRulesAgain(props) {
    const {header, content, isLogicTest} = props;
    const [modalShow, setModalShow] = useState(false);

    return (
        <Fragment>
            <button onClick={() => setModalShow(true)}>
                <FontAwesomeIcon icon={faInfoCircle} size="lg" className="mr-1"/>
                Revoir les règles
            </button>
            <RulesModal
                show={modalShow}
                onHide={() => setModalShow(false)}
                header={header}
                content={content}
                isLogicTest={isLogicTest}
            />
        </Fragment>
    )
}

export default SeeRulesAgain;