import { UPDATE_CANDIDATE } from '../actions/actionsCandidate'

const initialState = {
    candidate: null
};

const rootReducer = (state = initialState, action) => {
    if (action.type === UPDATE_CANDIDATE) {
        return Object.assign( {}, state, {
            candidate: action.candidate
        });
    } else {
        return state
    }
};

export default rootReducer;
