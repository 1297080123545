import React, {useEffect, useState} from 'react';
import Form from 'react-bootstrap/Form';
import {Button} from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {AUTO_PERCEPTION_PROFILE} from 'enums/autoPerceptionProfile';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTrash} from '@fortawesome/free-solid-svg-icons';
import DeleteButton from 'components/deleteButton/DeleteButton';

export default function AutoPerceptionQuestionConfig(props) {

    const [header, setHeader] = useState(props.question.header);
    const [choices, setChoices] = useState(props.question.choices);

    useEffect(() => {
        setHeader(props.question.header);
        setChoices(props.question.choices);
    }, [props.question]);

    /**
     * @desc Modifie la valeur de l'attribut d'une réponse
     * (réponse de la forme {label: labelValue, character: AUTO_PERCEPTION_PROFILE code}
     * @param index - index de la réponse
     * @param name - nom de l'attribut de la réponse
     * @param value - valeur de l'attribue de la réponse
     */
    const setAnswer = (index, name, value) => {
        let newChoices = [...choices];
        newChoices[index][name] = value;
        setChoices(newChoices);
    }

    //Ajoute une réponse à la question
    const addChoice = () => {
        let newChoices = [...choices, {label: '', character: ''}];
        setChoices(newChoices);
    }

    //Supprime une réponse de la question
    const removeChoice = (index) => {
        let newChoices = [...choices];
        newChoices.splice(index, 1)
        setChoices(newChoices);
    }

    //Construit la question et l'envoie au composant parent
    const handleSubmit = event => {
        event.preventDefault();
        const updatedQuestion = {
            header,
            choices
        }
        props.addOrUpdateQuestion(updatedQuestion);
    }

    /**
     * @desc Construit un composant pour chaque réponse de la question
     * @param answerList - liste de réponse
     */
    const answerFormGroupList = (answerList) => {
        //Si il y a déjà des réponses, on renvoie les input avec les valeurs existantes
        if (Array.isArray(answerList) && answerList.length > 0) {
            return answerList.map((answer, index) =>
                <Row key={index} className="answer-row align-items-center">
                    <Col md={7}>
                        <Form.Group>
                            <Form.Label>Réponse</Form.Label>
                            <Form.Control size="md" as="textarea" rows="3" name="label"
                                          value={answer.label}
                                          onChange={e => setAnswer(index, e.target.name, e.target.value)}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={4}>
                        <Form.Group>
                            <Form.Label>Profil</Form.Label>
                            <Form.Control as="select" name="character" value={answer.character}
                                          onChange={e => setAnswer(index, e.target.name, e.target.value)}>
                                <option hidden>Choisir un profil</option>
                                {Object.keys(AUTO_PERCEPTION_PROFILE)
                                    .map(key => {
                                        return <option key={key}>{AUTO_PERCEPTION_PROFILE[key].name}</option>
                                    })}
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col md={1}>
                        <FontAwesomeIcon className="removeAnswerButton" icon={faTrash} size="sm"
                                         onClick={() => removeChoice(index)}/>
                    </Col>
                </Row>
            );
        } else {
            //Sinon on ajoute une réponse vide
            addChoice();
        }
    };

    return (
        <div id="question-form">
            <Form onSubmit={handleSubmit}>
                <Form.Group>
                    <Form.Label>Intitulé de la question</Form.Label>
                    <Form.Control size="md" as="textarea" rows="1" name="header" value={header || ''}
                                  onChange={e => setHeader(e.target.value)}/>
                </Form.Group>
                {
                    answerFormGroupList(choices)
                }
                <Button
                    className="my-2 mt-2"
                    variant="outline-secondary"
                    size="sm"
                    onClick={addChoice}
                >
                    Ajouter un choix
                </Button>
                <Button
                    className="m-2"
                    variant="primary"
                    size="sm"
                    type="submit"
                >
                    Enregistrer
                </Button>
                {
                    //Si l'index est null, la question n'est pas encore dans la section donc on ne peut pas la supprimer
                    props.index !== null &&
                    <DeleteButton buttonText="Supprimer la question" handleDelete={props.deleteQuestion} size={"sm"}
                                  placement={"top"}/>
                }
            </Form>
        </div>
    )
}