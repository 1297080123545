import React, {useEffect, useState} from 'react';
import Form from 'react-bootstrap/Form';
import {Button, Spinner} from 'react-bootstrap';
import {createSection} from 'service/question';
import {getSectionPageUrl} from 'enums/pageUrl';
import {toast} from 'react-toastify';
import RichTextEditor from 'components/textEditor/RichTextEditor';
import RulesModal from 'components/modal/RulesModal';

export default function SectionForm(props) {

    const [loading, setLoading] = useState(false);
    const [modalShow, setModalShow] = useState(false);

    const [icon, setIcon] = useState(props.section.icon);
    const [label, setLabel] = useState(props.section.label);
    const [sectionRules, setSectionRules] = useState(props.section.rules);
    const [time, setTime] = useState(props.section.time);

    const isNewSection = !props.section.sectionId;

    useEffect(() => {
        setLabel(props.section.label);
        setSectionRules(props.section.rules);
        setTime(props.section.time);
    }, [props.section]);

    /**
     * @desc Crée la nouvelle section et l'envoie à dynamoDB
     */
    const handleSubmit = event => {
        setLoading(true);
        event.preventDefault();

        let newSection = {
            ...props.section,
            icon,
            label,
            rules: sectionRules,
            time
        };

        if (isNewSection) {
            createSection(newSection)
                .then((response) => {
                    //Une fois la création réussie, on fait une redirection vers la page de la nouvelle section
                    setLoading(false);
                    props.history.push(getSectionPageUrl(response.data.sectionName, response.data.sectionId));
                })
                .catch(error => {
                    setLoading(false);
                    toast.error(error.message, {position: toast.POSITION.TOP_RIGHT})
                })
        } else {
            props.sendNewSection(newSection);
            setLoading(false);
        }
    }

    return (
        <Form onSubmit={handleSubmit} className="section-form">
            <Form.Group>
                <Form.Label>Nom de la section</Form.Label>
                <Form.Control
                    type="text"
                    name="label"
                    value={label || ''}
                    required
                    onChange={(e) => setLabel(e.target.value)}
                />
            </Form.Group>
            <Form.Group>
                <Form.Label>Nom de l'icône</Form.Label>
                <Form.Control
                    type="text"
                    name="icon"
                    value={icon || 'palette'}
                    required
                    onChange={(e) => setIcon(e.target.value)}
                />
            </Form.Group>
            <Form.Group>
                <Form.Check
                    name="sectionTime"
                    type="checkbox"
                    checked={!!time}
                    onChange={e => setTime(e.target.checked ? 30 : null)}
                    label="Avec contrainte de temps"
                />
                {
                    time &&
                    <div className="mt-3">
                        <Form.Label>Durée du test (en minutes)</Form.Label>
                        <Form.Control type="number" min="0" max="120" step="5" value={time}
                                      onChange={e => setTime(e.target.value)}/>
                    </div>
                }
            </Form.Group>
            <Form.Group>
                <Form.Label>Règles de la section</Form.Label>
                <RichTextEditor className="mb-3" updateContent={(content) => setSectionRules(content)}
                                content={sectionRules}/>
                <Button size="sm" variant="outline-secondary" className="my-2" onClick={() => setModalShow(true)}>
                    Aperçu
                </Button>
            </Form.Group>
            <Button
                className="my-2"
                variant="primary"
                type="submit"
            >
                {
                    loading &&
                    <Spinner
                        size="sm"
                        as="span"
                        className="mr-2"
                        animation="border"
                        role="status"
                        aria-hidden="true"
                    />
                }
                {isNewSection ? 'Ajouter la section' : 'Enregistrer les modifications'}
            </Button>
            <RulesModal
                show={modalShow}
                onHide={() => setModalShow(false)}
                header={label || "Titre de la section"}
                content={sectionRules}
            />
        </Form>
    )
}