import React, {useEffect, useState} from 'react'
import {getUrlFile} from 'service/admin'
import {toast} from 'react-toastify'
import avatar from 'assets/avatar.svg'
import './Photo.scss'
import {Image} from 'react-bootstrap';

export default function Photo(props) {
    const [photo, setPhoto] = useState(avatar);

    useEffect(() => {
        getPhoto();
    }, [props.keyPhoto]);

    const getPhoto = () => {
        if (props.keyPhoto) {
            getUrlFile(props.keyPhoto)
                .then(result => {
                    setPhoto(result.data);
                })
                .catch(error => {
                    toast.error(error.message, {position: toast.POSITION.TOP_RIGHT})
                })
        }
    };

    return (
        <div className="avatar">
            <Image src={props.keyPhoto ? photo : avatar} alt='portrait du candidat'
                 className="photo"/>
        </div>
    )
}