import {useState} from "react"

// custom hook pour les formulaires
export function useFormFields(initialState) {
    const [fields, setValues] = useState(initialState);

    return [
        fields,
        function(event) {
            setValues({
                ...fields,
                [event.target.name]: event.target.value // met à jour la valeur du champ correspondant à l'évènement
            });
        }
    ];
}
