import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCheckCircle} from '@fortawesome/free-solid-svg-icons'
import React, {useEffect, useState} from 'react'
import {Button} from "react-bootstrap";
import {getRulesPageUrl} from "../../enums/pageUrl";

const FinishedTest = (props) => {
    const [nextSection, setNextSection] = useState();

    useEffect(() => {
        setNextSection(props.candidate?.sections.find(section => section.step === props.section.step + 1))
    }, [props.section]);

    return (
        <div className="paper-card validation-card">
            <FontAwesomeIcon className="icon-success" icon={faCheckCircle} size="2x"/>
            <div>
                Vous avez déjà terminé ce test.
            </div>
            {nextSection &&
                <Button className="mt-4" variant="primary" onClick={() =>
                    props.history.push(getRulesPageUrl(nextSection.sectionName, nextSection.sectionId))
                }>
                    Passer au test suivant
                </Button>
            }
        </div>
    )
};

export default FinishedTest;
