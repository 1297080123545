import React, {useState} from 'react'
import Comment from './Comment'
import NewComment from './NewComment'
import {updateCandidate} from "../../../../../service/admin";
import {toast} from "react-toastify";

export default function InterviewsCandidate(props) {
    const [loading, setLoading] = useState(false);

    const commentComponentList = (commentList) => {
        if(Array.isArray(commentList) && commentList.length > 0){
            return commentList.map((comment, index) =>
                <Comment key={index} comment={comment}
                         deleteComment={() => deleteComment(index)}
                         addComment={addComment}
                         candidate={props.candidate}
                />
            );
        } else {
            return null;
        }
    };

    const addComment = (newComment) => {
        let newCandidate = {...props.candidate};

        const existingCommentIndex = newCandidate.comments.findIndex(comment => comment.date === newComment.date);
        if (existingCommentIndex !== -1) {
            newCandidate.comments[existingCommentIndex] = newComment;
        } else {
            newCandidate.comments = [newComment, ...newCandidate.comments];
        }
        updateCandidate(props.candidate.candidateId, newCandidate).then((response) => {
            setLoading(false);
            toast.success('Commentaire ajouté', {position: toast.POSITION.TOP_RIGHT});
            props.candidateUpdated(response.data);
        })
        .catch(() => {
            setLoading(false);
            toast.error('Erreur lors de l\'ajout du commentaire', {position: toast.POSITION.TOP_RIGHT});
        });
    };

    const deleteComment = (index) => {
        let newCandidate = {...props.candidate};
        newCandidate.comments = [...newCandidate.comments];
        newCandidate.comments.splice(index, 1);
        updateCandidate(props.candidate.candidateId, newCandidate).then((response) => {
            toast.success('Commentaire supprimé', {position: toast.POSITION.TOP_RIGHT});
            props.candidateUpdated(response.data);
        })
        .catch(() => {
            toast.error('Erreur lors de la suppression du commentaire', {position: toast.POSITION.TOP_RIGHT});
        });
    }

    return (
        <div>
            <NewComment
                candidate={props.candidate}
                addComment={addComment}
                loading={loading}
                setLoading={setLoading}
            />
            {
                commentComponentList(props.candidate.comments)
            }
        </div>
    )
}