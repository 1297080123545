import React from "react"
import {Alert} from "react-bootstrap";

const EmptyListAlert = (props) => {

    return (
        <Alert variant={props.variant || "dark"} className={`empty-list-alert m-5 ${props.className || ""}`}>
            <span>La liste est vide {props.detailMassage ? `: ${props.detailMassage}` : ""}</span>
        </Alert>
    )
}

export default EmptyListAlert
