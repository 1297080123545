export const SESSION_ID = 'candidateId';
export const TOKEN = 'id_token';
export const CURENT_SECTION_ID = 'current_section_id';
export const ACCESS_TOKEN = 'access_token'
export const timeOut = 'Le temps est écoulé !';
export const INTENDED_URL= 'intended_url'

export const TYPE_DOCUMENT = {
    PDF: 'PDF',
    IMAGE: 'IMAGE',
};

export const STATUS = {
    trainee: 'Stagiaire',
    extern: 'Prestataire',
    intern: 'Recrutement'
}
