import React, {useEffect, useState} from 'react'
import { Route, Redirect } from 'react-router-dom'
import {TOKEN} from '../data/constant'
import {authenticate} from '../service/admin'

const ProtectedRoute = ({ component: Component, ...rest }) => {

    const [isAuthenticated, setIsAuthenticated] = useState(null);

    useEffect(() => {
        if (sessionStorage.getItem(TOKEN)) {
            authenticate()
                .then(() => {
                    setIsAuthenticated(true);
                })
                .catch(() => {
                    setIsAuthenticated(false);
                })
        }else{
            setIsAuthenticated(false);
        }
    }, [])

    return (
        (isAuthenticated !== null) &&
        <Route {...rest} render={
            props => {
                if(isAuthenticated){
                    return <Component {...rest} {...props} />
                } else {
                    return <Redirect to={
                        {
                            pathname: '/admin',
                            state: {
                                from: props.location
                            }
                        }
                    } />
                }
            }
        } />
    )
};

export default ProtectedRoute;
