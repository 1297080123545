import React, {Fragment, useEffect, useState} from 'react'
import Header from 'components/header/Header'
import {Button, Col, Row, Spinner} from 'react-bootstrap'
import './PerceptionQuestion.scss'
import InputNumber from './inputNumber/InputNumber'
import SeeRulesAgain from 'components/rules/SeeRulesAgain'
import {useDispatch, useSelector} from 'react-redux'
import {updateCandidate as updateCandidateBDD, getCandidateAndSection} from 'service/candidate'
import {updateCandidate as updateCandidateStore} from 'store/actions/actionsCandidate'
import {toast} from 'react-toastify'
import {CURENT_SECTION_ID} from 'data/constant'
import useLoader from 'components/loader/useLoader'
import FinishedTest from 'components/finishedTest/FinishedTest'
import {getPerceptionQuestionUrl, PAGES} from 'enums/pageUrl'

export default function PerceptionQuestion({history, match}) {

    const {params: {id}} = match;

    const dispatch = useDispatch();

    // Hook pour afficher un composant loader
    const [loader, showLoader, hideLoader] = useLoader();

    const [loading, setLoading] = useState(true);

    // Initialise le candidat avec sa valeur dans le store
    const [candidate, setCandidate] = useState(useSelector(state => state.candidate));
    const [section, setSection] = useState(null);

    // Etat initial du composant
    const [state, setState] = useState(
        {
            indexQuestion: parseInt(id),
            points: 10,
            counter: 0,
            question: section ? section.questions[parseInt(id)] : null,
            completed: section ? section.completed : false,
        }
    );

    //Récupère la section en cours et le candidat en BDD et met à jour l'état du composant
    useEffect(() => {
        if (!candidate || !section) {
            showLoader();
            getCandidateAndSection(localStorage.getItem(CURENT_SECTION_ID))
                .then(result => {
                    setCandidate(result.candidate);
                    setSection(result.section);
                    const question = result.section.questions[parseInt(id)];
                    const isCompleted = result.section.completed;
                    updateState(question, isCompleted);
                    hideLoader();
                })
        }
    }, []);

    // Lorsque l'id de la question change, on met à jour l'état du composant
    useEffect(() => {
        window.scrollTo(0, 0);
        if (candidate) {
            const question = section.questions[parseInt(id)];
            updateState(question);
        }
    }, [id]);


    // Met à jour l'état du composant si l'utilisateur a déjà répondu à la question
    const updateState = (question, isCompleted) => {
        if (question.answered) {
            let newCounter = 0;
            let points = 0;
            question.choices.forEach(choice => {
                if (choice.selected) {
                    newCounter++;
                }
                if (choice.points) {
                    points += choice.points
                }
            });
            setState({
                ...state,
                indexQuestion: parseInt(id),
                points: 10 - points,
                counter: newCounter,
                question: question,
                completed: isCompleted
            });
        } else {
            setState({
                indexQuestion: parseInt(id),
                points: 10,
                counter: 0,
                question: question,
                completed: isCompleted
            });
        }
    };

    // Met à jour l'état du composant lorsque l'utilisateur attribue 1 point
    const updateQuestion = (index, isIncreasing) => {
        // On récupère le choix de la question
        let choice = state.question.choices[index];

        // Variables intermédiaires
        let newCounter = state.counter;
        let newPoints = state.points;
        let newQuestion = {...state.question};

        //L'utilisateur ajoute 1 point
        if (isIncreasing) {
            newPoints--;
            //Update statePoints
            choice.points ? choice.points++ : choice.points = 1;
            if (!choice.selected) {
                choice.selected = true;
                newCounter++;
            }
        }
        // L'utilisateur retire 1 point
        else {
            newPoints++;
            choice.points--;
            if (choice.points === 0) {
                newCounter--;
                choice.selected = false;
            }
        }

        newQuestion.choices[index] = choice;

        setState({
            ...state,
            points: newPoints,
            counter: newCounter,
            question: newQuestion,
        });
    };

    //Lorsque l'utilisateur passe à la question suivante, met à jour le candidat dans la BDD
    //Passe à la question suivante
    const handleAnswer = () => {
        section.questions[state.indexQuestion] = {
            ...state.question,
            answered: Date.now()
        };

        setLoading(true);
        updateCandidateBDD(candidate)
            .then(() => {
                setLoading(false);
                dispatch(updateCandidateStore(candidate));
                if (state.indexQuestion === section.questions.length - 1) {
                    history.push(PAGES.PERCEPTION_VALIDATION);
                } else {
                    history.push(getPerceptionQuestionUrl(state.indexQuestion + 1));
                }
            })
            .catch(error => {
                setLoading(false);
                toast.error(error.message, {position: toast.POSITION.TOP_RIGHT});
            })
    };

    return (
        candidate && section ?
            <Fragment>
                <Header step={section.step} sections={candidate.sections}/>
                {state.completed ?
                    <FinishedTest
                        section={section}
                        history={history}
                        candidate={candidate}
                    />
                    :
                    (
                        state.question &&
                        <div>
                            <div className="perception col-8 offset-2">
                                <div className="info-quiz d-flex justify-content-between">
                                    <div>
                                        <span>{state.points} points restants </span> à attribuer
                                    </div>
                                    <div>
                                        <span>Question {state.indexQuestion + 1}</span> sur {section.questions.length}
                                    </div>
                                </div>
                                <Row className="paper-card text-left">
                                    <div className="header">{state.question.header}</div>
                                    <div className="w-100">
                                        {state.question.choices.map((choice, index) => {
                                            return (
                                                <Row key={index} className="d-flex align-items-center mb-3">
                                                    <Col sm={12} md={8} lg={9}>
                                                        <p className="mt-auto mb-auto">{choice.label}</p>
                                                    </Col>
                                                    <Col sm={12} md={4} lg={3} className="d-block text-right">
                                                        <InputNumber
                                                            state={state}
                                                            setState={setState}
                                                            choiceIndex={index}
                                                            points={choice.points}
                                                            updateQuestion={updateQuestion}
                                                        />
                                                    </Col>
                                                </Row>
                                            )
                                        })}
                                    </div>
                                </Row>
                                <Row className="text-left col-12 mt-4">
                                    <SeeRulesAgain header={section.label} content={section.rules} isLogicTest={false}/>
                                </Row>
                                <div>
                                    {state.indexQuestion !== 0 ?
                                        <Button
                                            onClick={() => (history.push(getPerceptionQuestionUrl(state.indexQuestion - 1)))}
                                            className="m-2"
                                            variant="outline-primary"
                                            size="lg"
                                        >
                                            Question précédente
                                        </Button>
                                        :
                                        null
                                    }
                                    {state.indexQuestion < section.questions.length - 1 ?
                                        <Button
                                            onClick={handleAnswer}
                                            className="m-2"
                                            variant="primary"
                                            size="lg"
                                            disabled={state.points !== 0 || state.counter < 2}
                                        >
                                            Question suivante
                                        </Button>
                                        :
                                        null
                                    }
                                    {state.indexQuestion === section.questions.length - 1 ?
                                        <Button
                                            onClick={handleAnswer}
                                            className="m-2"
                                            variant={state.points === 0 && state.counter >= 2 ? 'success' : 'outline-danger'}
                                            size="lg"
                                            disabled={state.points !== 0 || state.counter < 2}
                                        >
                                            Terminer le test

                                            {
                                                loading &&
                                                <Spinner
                                                    size="sm"
                                                    as="span"
                                                    className="mr-2"
                                                    animation="border"
                                                    role="status"
                                                    aria-hidden="true"
                                                />
                                            }

                                        </Button>
                                        :
                                        null
                                    }
                                </div>
                            </div>
                        </div>)
                }
            </Fragment>
            :
            loader
    )
}