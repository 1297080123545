import React from 'react'
import {Button, Spinner} from 'react-bootstrap'
import './ValidationCard.scss'

export default function ValidationCard(props) {

    const {sectionLabel, method, loading} = props;

    return (
        <div>
        <div className="paper-card validation-card">
            <div className="my-5">
                <p>Vous venez de terminer la partie <b>{sectionLabel.toLowerCase()}</b> !</p>
                <p>Veuillez cliquer sur enregistrer pour valider vos réponses.</p>
            </div>
            <Button
                variant="primary"
                size="lg"
                disabled={loading}
                onClick={method}
            >
                {loading &&
                <Spinner
                    className="mr-2"
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                />
                }
                Enregistrer
            </Button>
        </div>
        </div>
    )
}